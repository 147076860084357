/*
# f-text-input

A text input field, with a label, that can be one line or multiple lines.

<pre>
BLOCK f-text-input
MODIFIER f-text-input_readonly
MODIFIER f-text-input_multiline
MODIFIER f-text-input_disable
	ELEMENT f-text-input__label
	ELEMENT f-text-input__text-field
	MODIFIER f-text-input__text-field_btc
</pre>

## Input field
```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

	<!-- this should be used on the website -->

	<div class="f-text-input">
		<label class="f-text-input__label">Name
			<input class="f-text-input__text-field" type="text" placeholder="Name, organization, newspaper, etc.">
		</label>
	</div>

	<!-- this should be used on the website -->

</div>
```

## Mutiple lines
```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

	<!-- this should be used on the website -->

	<div class="f-text-input f-text-input_multiline">
		<label class="f-text-input__label">Message
			<textarea class="f-text-input__text-field" rows="6" placeholder="This is OpenPGP encrypted using the OpenPGP.js library"></textarea>
		</label>
	</div>

	<!-- this should be used on the website -->

</div>
```

## Readonly Input field
```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

	<!-- this should be used on the website -->

	<div class="f-text-input f-text-input_readonly">
		<label class="f-text-input__label">Name
			<input class="f-text-input__text-field" readonly type="text" value="PGP stuff.">
		</label>
	</div>

	<!-- this should be used on the website -->

</div>
```

## Readonly mutiple lines
```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

	<!-- this should be used on the website -->

	<div class="f-text-input f-text-input_readonly f-text-input_multiline">
		<label class="f-text-input__label">Name
			<textarea class="f-text-input__text-field" rows="6" readonly>
-----BEGIN PGP PUBLIC KEY BLOCK-----
Version: GnuPG v1.4.13 (MingW32)

mI0EVzpzJAEEAOczHUF98HyisH1XDN2i3amY2Q7V3hjfhKi4VC5FevgkFH//tPUh
1zDld92b1zmC2aBj4tJ7B57g7ohBQO/5KOTf6GVQLuuqAXLNyUdbMa1xtOIP8byb
HgyFDyhol5GRBeCxvbL8EHJEY/WEa2XIS6NcDjDJYrg8ERFnF7PladTxABEBAAG0
CVRlc3QgTWFuZIi+BBMBAgAoBQJXOnMkAhsDBQkAJ40ABgsJCAcDAgYVCAIJCgsE
FgIDAQIeAQIXgAAKCRCfcYCk0/uwS2kcBACCebL1e02SG/No1gH6/1A1ifQhj9d7
J5d8o69lQEI6niegT27jKRI+D5Bo8tQfX3i4eftTAJBUlWxcEk1eCE7mf18aiowM
GZ8aizTtf3CIIKq8lhPdHLsAUcPgZOARBAJEpjC/kvwFAJesmPx08l+CmfLOcOxz
KSnWsAxpPwrUnbiNBFc6cyQBBADJoYbvUqmyWQlPc/vo6ugppQiuHGURSIyAXXH9
sQ+36n8yzTED/8s2jmj2WEbFP47K1NVIBgov6V/C8eCMjScoIxqmOnlh7prJ2rg7
5EwwPj2As5ciIwow+t30skJRvwAxh0qa8+oicwsY4KTjAEiFzbqLIv8/GTjGmbH6
5+LNAwARAQABiKUEGAECAA8FAlc6cyQCGwwFCQAnjQAACgkQn3GApNP7sEvPnAP/
YRPMk0RdBSXCKBkt94Pukr6lPulBt+SzhTGarjYP0cnIcaqGeJOgH8dLlbpBvmx6
snBsJAo3cIjqDmw/E7+VOpdk0jFrcI4fKUXcT2E7ywAzszQ7ayc8QLimUQJyba+/
LjSacZsYqWRmM+9k6w3XZren33fIXweEguZ7raQapV8=
=q3KY
-----END PGP PUBLIC KEY BLOCK-----
			</textarea>
		</label>
	</div>

	<!-- this should be used on the website -->

</div>
```

## Disabled input field
```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

	<!-- this should be used on the website -->

	<div class="f-text-input f-text-input_disable">
		<label class="f-text-input__label">Name
			<input class="f-text-input__text-field" disabled type="text" placeholder="Name, organization, newspaper, etc.">
		</label>
	</div>

	<!-- this should be used on the website -->

</div>
```

## Disabled mutiple lines
```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

	<!-- this should be used on the website -->

	<div class="f-text-input f-text-input_multiline f-text-input_disable">
		<label class="f-text-input__label">Message
			<textarea class="f-text-input__text-field" rows="6" disabled placeholder="This is OpenPGP encrypted using the OpenPGP.js library"></textarea>
		</label>
	</div>

	<!-- this should be used on the website -->

</div>
```
*/

@import "../variables/variables.css";

.f-text-input {
	font-size: 1.4rem;
	line-height: 2rem;
}

.f-text-input__label {
	position: relative;
	display: block;

	margin-left: 0.2rem; /* align with text-field -  adjust to the border  */

	color: var(--color-grey-dark);
	line-height: 1.8;
}

.f-text-input__text-field {
	display: block;
	width: 122%;
	padding: 0.5rem;
}

.f-text-input__text-field__mobilepay {
	display: block;
	width: fit-content !important; 
	outline: none;
	padding: 0.5rem;
}

.f-text-input__text-field_btc {
	height: 50px;
	width: 100%;
	font-size: 1.4rem;
	color: var(--color-black);
}

.f-text-input__text-field::placeholder {
	padding-left: .5rem; /*TODO: only works in chrome */
}

.f-text-input_disable * {
	cursor: not-allowed;
}

.f-text-input_multiline, .f-text-input__text-field {
	width: 100%;
	margin: 0 0 2rem 0.1rem;

	border: 1px solid var(--color-black);
	border-radius: .3rem;
	
	resize: vertical;
}

.f-text-input_readonly .f-text-input__text-field {
	color: var(--color-grey);

	border-color: var(--color-grey-light);
	background-color: var(--color-grey-light);
}

@media (--viewport-mobile) {
  .f-text-input__text-field_btc {
	
	font-size: 2.4rem;
	width: 60%;
	
  }

  .f-text-input {
  
  font-size: 2.4rem;
  
  }
}
/*
This is the sitemap to be shown in the left of the footer
`f-sitemap__branding` is a [BEM mix of f-branding](https://en.bem.info/forum/4/)

<pre>
BLOCK f-sitemap
	ELEMENT f-sitemap__branding <- mix of f-branding
	ELEMENT f-sitemap__links
</pre>

## EXAMPLE 1
```
<div class="f-sitemap">
	<h2 class="f-branding f-sitemap__branding">Fire<span>fund</span></h2>
	<ul class="f-sitemap__links">
		<li><a href="https://medium.com/@firefundnet/latest">Blog &gt;</a></li>
		<li><a href="/about/about-team">Team</a></li>
		<li><a href="/about/about-terms">Terms</a></li>
		<li><a href="/about/about-contact">Contact</a></li>
		<li><a href="/about/about-us">About us</a></li>
		<li><a href="/about/about-manifesto">Manifesto</a></li>
		<li><a href="/about/about-us">Who we are</a></li>
		<li><a href="/about/howdoesitwork">How does it work</a></li>
	</ul>
</div>
```
*/

@import "../variables/variables.css";

.f-sitemap__links {
	margin-top: 0;
	padding: 0;

	font-weight: lighter;
	font-size: 1.7rem;
	line-height: 1.6;

	list-style: none;
}

.f-sitemap__links a {
	color: var(--color-grey-light);

	transition: color 260ms ease-out;
}
.f-sitemap__links a:hover {
	color: var(--color-white);
}

@media (--viewport-mobile) {
  .f-sitemap__links {
    font-size: 2.4rem;
    line-height: 1.8;
  }

  .f-branding.f-sitemap__branding {
    font-size: 3.7rem;
    font-weight: bold;
  }
}

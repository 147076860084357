/*
Display how much is donated out of several "stretch" goals.

There are 2 ways to create a `f-progress-stretch`:

```html
<section
	class="f-progress-stretch"
	data-amount="<%= amountPledged %>"
	data-list-of-goals="[5000, 20000, 50000, 100000]"
	data-bar-height="12rem",
	data-days-left="<%= daysLeft %>"
	data-zero="€0"
>
```

```js
$('.f-progress-stretch').stretchGoals();
```
_When using `data-*` attributes, each f-progress-stretch can display different goals and value._

or

```html
<section class="f-progress-stretch">
```

```js
$('.f-progress-stretch').stretchGoals({
	listOfGoals: [5000, 20000, 50000, 100000],
	amount: <%= amountPledged %>,
	barHeight: "30rem",
	daysLeft: <%= daysLeft %>,
	zero: "€0"
});
```
_When using a JS object to set values, the values will be the same on all `f-progress-stretch` on the page._

| data-* | JS property | Required | Data Type | Description |
| ------ | ----------- | -------- | --------- | ----------- |
| data-amount | amount | Yes | Number | The current amount to display |
| data-list-of-goals | listOfGoals | Yes | Number[] | List of stretch goals |
| data-days-left | daysLeft | Maybe | Number/String | Display how many days are left |
| data-bar-height | barHeight | No | [CSS-length](https://developer.mozilla.org/en-US/docs/Web/CSS/length) | The height of the bar chart. Default is `30rem`. |
| data-zero | zero | No | String | The value to display at the start value at the bottom of the bar chart. |
| data-no-summary | noSummary | No | String | The value to display at the start value at the bottom of the bar chart. |

An example of what you minimum need is:

```html
<section
	class="f-progress-stretch"
	data-amount="<%= amountPledged %>"
	data-list-of-goals="[20000,25000,30000]"
	data-days-left="<%= daysLeft %>"
></section>
```


## BEM
<pre>
BLOCK f-progress-stretch
	ELEMENT f-progress-stretch__summary
		ELEMENT f-progress-stretch__amount
		ELEMENT f-progress-stretch__days
		ELEMENT f-progress-stretch__percentage
	ELEMENT f-progress-stretch__graph
	ELEMENT f-progress-stretch__bar
		MODIFIER f-progress-stretch__bar_active
		ELEMENT f-progress-stretch__current-amount
		ELEMENT f-progress-stretch__fill
		ELEMENT f-progress-stretch__bar
		ELEMENT f-progress-stretch__top-tick
		ELEMENT f-progress-stretch__middle-tick
		ELEMENT f-progress-stretch__bottom-tick
		ELEMENT f-progress-stretch__description
		ELEMENT f-progress-stretch__description-text
		ELEMENT f-progress-stretch__description-list
</pre>

| Description | Line | UTF-8  |
| ----------- |:----:| ------ |
| High        | ˉ    | U+02C9 |
| Middle      | -    | U+002D |
| Low         | ˍ    | U+02CD |

## Example

This is show for show, since what you need to use is `firefund.stretchGoal()`. See above.

```
<div class="kalei-background-white" style="max-width:640px;"> <!-- only here for the style guide - should not be used on the website -->

	<!-- 👇 should be used on the website -->

<section class="f-progress-stretch">
	<p class="f-progress-stretch__summary">
		<span title="€43.000 reached in total"
			class="f-progress-stretch__amount">€43.000</span>
		<span title="24 days to reach the campaign goals"
			class="f-progress-stretch__days">24 days left</span>
		<span title="860% of €5.000"
			class="f-progress-stretch__percentage">860%</span>
	</p>

	<div class="f-progress-stretch__graph">
		<div class="f-progress-stretch__bar">
			<p class="f-progress-stretch__top-tick">€100.000</p>

			<div class="f-progress-stretch__fill"></div> <!-- red bar -->
		</div>

		<div class="f-progress-stretch__bar f-progress-stretch__bar_active">
			<p class="f-progress-stretch__current-amount">
				<span>€43.000</span>
			</p>

			<div class="f-progress-stretch__fill"></div> <!-- red bar -->

			<p class="f-progress-stretch__middle-tick">€50.000</p>
		</div>

		<div class="f-progress-stretch__bar">
			<div class="f-progress-stretch__fill"></div> <!-- red bar -->

			<p class="f-progress-stretch__middle-tick">€20.000</p>
		</div>

		<div class="f-progress-stretch__bar">
			<div class="f-progress-stretch__fill"></div> <!-- red bar -->

			<p class="f-progress-stretch__middle-tick">€5.000</p>
			<p class="f-progress-stretch__bottom-tick">€0</p>
		</div>
	</div>

	<div class="f-progress-stretch__description">
		<p class="f-progress-stretch__description-text">
			This campaign runs on a stretch goal, meaning the goals of the campaign can be scaled, depending on how much it reaches.
		</p>
		<p class="f-progress-stretch__description-text">
			On the vertical bar you will see the different milestones of this campaign and how close the campaign is to reaching these.
		</p>
		<p class="f-progress-stretch__description-text">
			Everything above the 100% mark - the first goal - will be transferred to the project.
		</p>
		<ol class="f-progress-stretch__description-list">
			<li><strong>First Goal:</strong> €5.000</li>
			<li><strong>Second Goal:</strong> €20.000</li>
			<li><strong>Third Goal:</strong> €50.000</li>
			<li><strong>Final Goal:</strong> €100.000</li>
		</ol>
	</div>
</section>

	<!-- ☝️ should be used on the website -->

</div>
```
*/

@import "../variables/variables.css";

.f-progress-stretch
{
	--amount: 43000;
	--zero-amount: 20000;
	--goal-amount: 50000;
	--percent-local: min(110%, calc((var(--amount) - var(--zero-amount)) / (var(--goal-amount) - var(--zero-amount)) * 100%));
	--text-distance-from-bar: 1.9rem;
	--bar-height: 30rem;

	margin-bottom: var(--margin-text);
}

.f-progress-stretch__summary
{
	margin: 0 0 2.8rem;

	color: var(--color-grey-dark);
	font-size: 1.8rem;
	line-height: 2.0rem;
	text-align: right;
}

.f-progress-stretch__amount
{
	font-weight: bold;
}

.f-progress-stretch__days
{
	color: var(--color-black);
	font-weight: bold;
}

.f-progress-stretch__percentage
{
	color: var(--color-grey);
}

.f-progress-stretch__graph
{
	position: relative;
	display: flex;
	flex-direction: column;
	height: var(--bar-height); /* will be set via script */
	margin-bottom: 2.8rem;
}

.f-progress-stretch__bar
{
	position: relative;
	right: calc(-50%);
	width: 1rem;
	height: 100%; /* proportional */

	background-color: var(--color-grey-dark);
}

.f-progress-stretch__fill,
.f-progress-stretch__bar_active .f-progress-stretch__fill
{ /* the red fill inside .f-progress-stretch__bar */
	position: absolute;
	bottom: 0;
	width: inherit;
	height: var(--percent-local);

	background-color: var(--color-red);
}
.f-progress-stretch__fill
{
	/* every bar before the active has 0% height - goal not begun */
	height: 0%;
}
.f-progress-stretch__bar_active ~ .f-progress-stretch__bar .f-progress-stretch__fill
{
	/* every bar after the first has 100% height - goal achieved */
	height: 100%;
}

.f-progress-stretch__top-tick, /* the active goal */
.f-progress-stretch__top-tick::after,
.f-progress-stretch__current-amount, /* current donations */
.f-progress-stretch__current-amount::after,
.f-progress-stretch__middle-tick, /* previous achieved goal */
.f-progress-stretch__middle-tick::after,
.f-progress-stretch__bar:last-child::after, /* add 0% tick to bottom of bar */
.f-progress-stretch__bottom-tick
{
	/* positioning elements */
	position: absolute;
	margin: 0;

	color: var(--color-grey);
	white-space: nowrap;
}

.f-progress-stretch__top-tick, /* the final end goal */
.f-progress-stretch__middle-tick, /* previous achieved goal */
.f-progress-stretch__bottom-tick
{
	left: var(--text-distance-from-bar);
}

.f-progress-stretch__top-tick::after,
.f-progress-stretch__middle-tick::after
{
	left: -1rem;
}

.f-progress-stretch__bar:last-child::after
{
	left: 1rem;
}

.f-progress-stretch__current-amount,
.f-progress-stretch__current-amount::after
{
	right: var(--text-distance-from-bar);
}

.f-progress-stretch__current-amount::after
{
	right: -1rem;
}

.f-progress-stretch__top-tick {
	top: 0;
}
.f-progress-stretch__top-tick::after
{
	top: -.2em;
	content: "ˉ";
}

.f-progress-stretch__current-amount
{
	bottom: calc(var(--percent-local) - 2.2rem / 2);

	line-height: 2.2rem;
	font-weight: normal;
}

.f-progress-stretch__middle-tick {
	top: -.6em;
}

.f-progress-stretch__current-amount::after,
.f-progress-stretch__middle-tick::after
{
	top: -.1em;
	content: "-";
}

.f-progress-stretch__bar:last-child::after
{ /* add 0% tick to bottom of bar */
	right: .9rem;
	bottom: -.1em;

	content: "ˍ";
}

.f-progress-stretch__bottom-tick
{
	bottom: 0;
}

.f-progress-stretch__description
{
	margin: 0 0 2rem 0;

	color: var(--color-grey);
	font-size: 1.5rem;
	font-style: italic;
	font-weight: 300;
	line-height: 1.8rem;
}

.f-progress-stretch__description-text
{
	margin: 0 0 0.5rem;
}

.f-progress-stretch__description-list
{
	padding: 0;
	list-style: none;
}

/*
##EXAMPLE 1
```
<footer class="f-footer">
	<div class="f-spacer"></div>

  <div class="f-row f-row_12">
    <div class="f-row__cell-mobile-5 f-row__cell-mobile_push_1 f-row__cell-3 f-row__cell_push_1">
      <div class="f-footer-sitemap">
        <h2 class="f-branding">Fire<span>fund</span></h2>
      </div>
		</div>

    <div class="f-row__cell-mobile-5 f-row__cell-8">
      <p class="f-footer-text">
        FIRE<span>FUND</span> is for radical political crowdfunding.<br>
      </p>
    </div>
	</div>

  <div class="f-row f-row_12">
		<div class="f-row__cell-mobile-5 f-row__cell-mobile_push_1 f-row__cell-3 f-row__cell_push_1">
			<ul class="f-sitemap__links">
				<li><a href="/about/about-us">About us</a></li>
				<li><a href="https://medium.com/@firefundnet/latest">Blog &gt;</a></li>
				<li><a href="/about/about-terms">Terms</a></li>
				<li><a href="/about/about-contact">Contact</a></li>
				<li><a href="/about/about-manifesto">Manifesto</a></li>
				<li><a href="/about/about-team">Team</a></li>
				<li><a href="/about/about-us">Who we are</a></li>
				<li><a href="/about/howdoesitwork">How does it work</a></li>
			</ul>
		</div>

		<div class="f-row__cell-mobile-5 f-row__cell-7 f-row__cell_push_1">
			<ul class="f-footer-icons f-icon-list f-icon-list_horizontal">
				<li><a class="f-icon-list__icon f-icon-facebook" title="Link to our facebook page" href="https://www.facebook.com/firefund.net/"></a></li>
				<li><a class="f-icon-list__icon f-icon-instagram" title="Checkout our instagram" href="https://www.instagram.com/firefundnet/"></a></li>
				<li><a class="f-icon-list__icon f-icon-twitter" title="Checkout our twitter" href="https://twitter.com/firefundnet"></a></li>
				<li><a class="f-icon-list__icon f-icon-youtube" title="Checkout our YouTube channel" href="https://www.youtube.com/channel/UCpg_6P88q1uAub7aPHyd9Xg"></a></li>
				<li><a class="f-icon-list__icon f-icon-medium" title="Checkout our medium blog" href="https://medium.com/@firefundnet/latest"></a></li>
			</ul>
		</div>

	</div>

	<div class="f-spacer"></div>
</footer>
```
*/

@import "../variables/variables.css";
.f-footer {
	lost-center: var(--page-max-width);
}

.f-footer-text {
	text-align: right;
}

.f-footer-icons.f-icon-list {
	color: var(--color-white);
	text-align: left;
	line-height: 1.7;
}

@media (--viewport-mobile) {
	.f-footer-text {
		text-align: justify;
	}
}


/*
#Notes#

Here's the payment module's CSS!

WE HAVE INCORRECT BEM STRUCTURE HERE!
Why is it f-payment-form, and not f-payment? As the block?
BlockElementModifier!

<pre>
BLOCK f-payment
	ELEMENT f-payment__credit
	ELEMENT f-payment__bitcoin

	MODIFIER f-payment_step-bitcoin
	MODIFIER f-payment_step-credit
	ELEMENT f-payment__footer-credit
	ELEMENT f-payment__footer-bitcoin

	ELEMENT f-payment__heading
	ELEMENT f-payment__text
	ELEMENT f-payment__button
	ELEMENT f-payment__button-group

	ELEMENT f-payment_element

	ELEMENT f-payment__arrow - are currently inactive
	ELEMENT f-payment__arrow_mobile - are currently inactive

	ELEMENT f-payment__radio-group
	ELEMENT f-payment__radio-element
	ELEMENT f-payment__radio-input

	ELEMENT f-payment__footer-text
	ELEMENT f-payment__range-label
	ELEMENT f-payment__slider
	ELEMENT f-payment__slider-style
</pre>


# Payment form

<button class="f-button-basic">Toggle modal window</button>
```
	<div class="f-modal f-modal_visible">
		<div class="f-modal__content">
			<div class="f-content">
				<!-- The one and only -->

				<!-- Step 1 -->
				<!-- Insert f-payment_step-credit or f-payment_step-bitcoin here -->
				<div class="f-payment">
					<div class="f-row f-row_12">
						<div class="f-row__cell-7 f-row__cell-mobile_push_2 f-row__cell-mobile-8">
							<h2 class="f-payment__heading"> Choose the amount and method of payment</h2>
							<div class="f-text">
								<p class="f-text__text">A <a href="#">credit card</a> donation will only be transferred if the campaign reaches the goal in time.</p>
								<p class="f-text__text">With bitcoin donations, all donations are instant and refunds are not currently possible.</p>
							</div>
							<button class="f-button-basic f-payment__button" onclick="showBitcoinPaymentForm()">Bitcoin</button>
							<button class="f-button-basic f-payment__button" onclick="showCreditCardPaymentForm()">Credit card</button>
						</div>
						<div class="f-row__cell-4 f-row__cell-mobile_push_2 f-row__cell-mobile-7">
							<div class="f-payment__credit">
								<div class="f-payment__radio-group f-radio-group">
									<div class="f-column f-column_2 f-column_mobile_3">
										<div class="f-payment__radio-element f-radio-group__element">
											<input id="rb10" type="radio" name="amountRadio" value="500">
											<label for="rb10">€500</label>
										</div>
										<div class="f-payment__radio-element f-radio-group__element">
											<input id="rb11" type="radio" name="amountRadio" value="250">
											<label for="rb11">€250</label>
										</div>
										<div class="f-payment__radio-element f-radio-group__element">
											<input id="rb12" type="radio" name="amountRadio" value="100">
											<label for="rb12">€100</label>
										</div>
										<div class="f-payment__radio-element f-radio-group__element">
											<input id="rb13" type="radio" name="amountRadio" value="50">
											<label for="rb13">€50</label>
										</div>
										<div class="f-payment__radio-element f-radio-group__element">
											<input id="rb14" type="radio" name="amountRadio" checked="true" value="20">
											<label for="rb14">€20</label>
										</div>
										<div class="f-text-input f-payment__radio-element f-radio-group__element f-payment__radio-input">
											<input id="rb15" type="radio" name="amountRadio" value="customValue">
											<label for="rb15" onclick="this.previousElementSibling.checked = true;">
												<input id="customValue" class="f-text-input__text-field" type="number" placeholder="€ 10">
											</label>
										</div>
										<button class="f-button-donate" onclick="submitPayment()">Donate</button>
									</div>
								</div>
							</div>

							<!-- Step Bitcoin! -->
							<div class="f-payment__bitcoin">
								<div class="f-row__cell-12">
									<figure class="f-media">
										<img class="f-media__media" src="https://firefund-assets.s3.amazonaws.com/skopje-qr.png">
									</figure>
									<div class="f-text-input">
										<label class="f-text-input__label">To bitcoin adress:
											<textarea class="f-text-input__text-field f-text-input__text-field_btc" readonly type="text" placeholder="1KKfVhwZgYe4HtzFi8489TKmKeU8ziyL3a"></textarea>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="f-row_cell-12">

						<!-- footer for credit -->
						<div class="f-payment__footer f-payment__footer-credit">
							<div class="f-row f-row_12">
								<div class="f-row__cell-6 f-row__cell-mobile_push_2 f-row__cell-mobile-7">
									<div class="f-payment__footer-text">
										You decide the cut. Please, keep in mind that FIREFUND is non-profit and activist-driven.
									</div>
								</div>
								<div class="f-row__cell-1 f-row__cell-mobile_push_2 f-row__cell-mobile-1">
									<p class="f-payment__footer-text f-payment__range-label">FIREFUND</p>
									<p class="f-payment__footer-text f-payment__range-label">CAMPAIGN</p>
								</div>
								<div class="f-row__cell-3">
									<div class="f-payment__slider">
										<input value="10" id="firefund-slider" class="f-payment__slider-style" name="firefund-slider" type="range"/>
									</div>
									<div class="f-payment__slider">
										<input value="90" id="project-slider" class="f-payment__slider-style" name="project-slider" type="range"/>
									</div>
								</div>
								<div class="f-row__cell-2">
									<div id="firefund-amount" class="f-payment__footer-text f-payment__range-label">€2.00</div>
									<div id="project-amount" class="f-payment__footer-text f-payment__range-label">€18.00</div>
								</div>
							</div>
						</div>

						<!-- footer for bitcoin -->
						<div class="f-payment__footer f-payment__footer-bitcoin">
							<div class="f-row f-row_12">
								<div class="f-row__cell-9 f-row__cell-mobile_push_2 f-row__cell-mobile-8">
									<div class="f-payment__footer-text">
										For this campaign all bitcoins will be transferred directly to the activists with no platform fee. Bitcoins are not refundable at the moment. Read more about our <a href="/about-terms">terms</a>.
									</div>
								</div>
							</div>
						</div>

					</div>

				</div>
				<!-- The one and only ends here -->
			</div><!-- /f-content -->
		</div><!-- /f-modal__content -->
	</div><!-- /f-modal -->
```
*/

@import "../variables/variables.css";
.f-payment {
	position: relative;

	padding-top: 2.3rem;
	/* height: calc(100vh - 10rem - 2.3rem - 2rem); minus all top/bottom padding and margin - yes it's a mess  */
	/* padding-top 10rem from f-modal, 2.3rem from here */
	/* padding-bottom 2rem from f-payment__footer-credit */
	overflow-y: auto;
	overflow-x: hidden;

	background-color: var(--color-grey-light-warm);
}

.f-payment_element {
	margin: 4.8rem 2.6rem 0 1.64rem;
}

.f-payment_element__email::placeholder {
	color: var(--color-grey);
}

.f-payment_element .StripeElement {
	background-color: var(--color-white);
	padding: 1rem;
	margin: 0;
	border: 1px solid var(--color-transparent);
	border-radius: 4px;
}

/*.f-payment .f-payment__bitcoin {
	height: 46.5rem;
}*/

.f-payment__credit {
	position: relative;
	display: none;

	height: 100%;

	transition: opacity 500ms ease;

	pointer-events: unset;
	opacity: 1;
}

.f-payment__bitcoin {
	position: relative;
	display: none;

	height: 100%;

	transition: opacity 500ms ease;

	pointer-events: unset;
	opacity: 1;
}

.f-payment_step-credit .f-payment__credit {
	display: block;
	/*height: 50rem;*/

	/*pointer-events: unset;*/
}

.f-payment_step-bitcoin .f-payment__bitcoin {
	display: block;
	/*height: 50rem;*/

	/*pointer-events: unset;*/
}

.f-payment__footer-credit {
	position: relative;
	display: none;

	margin: 1rem calc(var(--gutter-width) * -1) 0 calc(var(--gutter-width) * -1);
	padding: 0 0 2rem 3.4rem;

	/*background: var(--color-grey-light);*/
	background: var(--color-grey-light-warm);

	transition: transform 250ms ease-out;
}

.f-payment__footer-bitcoin {
	display: none;
	position: relative;

	margin: 1rem calc(var(--gutter-width) * -1) 0 calc(var(--gutter-width) * -1);
	padding: 3.4rem 0 2rem 3.4rem;

	/*background: var(--color-grey-light);*/
	background: var(--color-grey-light-warm);

	transition: transform 250ms ease-out;
}

/* .f-payment__footer-fee {
	background-color: pink;
} */


.f-payment_step-bitcoin .f-payment__footer-bitcoin {
	display: block;
}

.f-payment_step-credit .f-payment__footer-credit {
	display: block;
}


.f-payment__heading {
	margin: 0 0 1.9rem -3.5rem; /*3.5rem instead of variable to eliminate the thin line*/
	padding: .8rem 1.93rem .8rem var(--gutter-width);

	color: var(--color-white);
	font-size: 2.6rem;
	line-height: 2.7rem;

	background-color: var(--color-black);
}

.f-payment__text {
	font-size: 2rem;
	height: 10.8rem;
	margin: 0;
}

.f-payment .f-payment__button {
	width: 100%;
	height: 60%;


	margin: 1rem 0 0 0;

	font-size: 2.0rem;
	font-weight: lighter;
}

.f-payment__button-group {
	position: absolute;
	bottom: 0;

	width: 22rem;
}

input,
.f-payment__card-element {
  box-sizing: border-box;

/*  font-size: 18px;*/

  height: 60%;
  width: 100%;

  padding: 10px 12px;
  margin: 1rem 1rem 1rem 0;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  outline-color: var(--color-grey-light);

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

input:focus,
.f-payment__card-element--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

input.f-payment__card-element--invalid {
  border-color: #fa755a;
}

input.f-payment__card-element--webkit-autofill {
  background-color: #fefde5 !important;
}


/*.f-payment__arrow {
	width: 3rem;
	height: 3rem;

	margin: 11rem 0 0 -1.5rem;

	border-bottom: 5px solid var(--color-grey-light);
	border-right: 5px solid var(--color-grey-light);

	transform: rotate(-45deg);
}*/

/*.f-payment__arrow_mobile {
	margin: 1rem 0 2rem 12rem;
	transform: rotate(45deg);
}*/

.f-radio-group.f-payment__radio-group {
	margin: 8.6rem -4.4rem 0rem 0.8rem;

	font-weight: bold;
}


.f-payment__radio-element {
	margin: 0rem 0rem 2.5rem 0rem !important;
}                        /*Sorry Jon! ^^^*/

.f-payment__radio-input .f-text-input__text-field {
	display: inline-block;

	width: calc(100% - .4rem); /* KLUDGE: .4rem = magic number */
	height: 2.4rem;

	line-height: normal;
	vertical-align: middle;

	-moz-appearance: textfield; /* KLUDGE: remove controls from Firefox */
}

/*
	KLUDGE: remove controls from Safari and Chrome
	by styling ::-webkit-inner-spin-button and ::-webkit-outer-spin-button
*/

.f-payment__radio-input .f-text-input__text-field::-webkit-inner-spin-button,
.f-payment__radio-input .f-text-input__text-field::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Removes leftover margin */
}

.f-text-input.f-payment__radio-input label {
	position: absolute;
	top: 0;
	left: 0;

	padding-left: 3.4rem;
}

.f-payment__footer-text {
	color: var(--color-grey);
	font-size: 1.2rem;
	font-weight: 100;
	line-height: 1.8rem;
	margin-bottom: 2rem;
}


.f-payment__range-label {
	margin: 0;
	padding: 1rem 0 0 1rem;

	line-height: 2.2rem;
}

.f-payment__slider { position: relative; }


/* NORMALIZE SLIDER */
input[type="range"].f-payment__slider-style {
	/*position: absolute;*/
	width: 100%; /* Specific width is required for Firefox. */
	height: 6rem;

	margin: 0;

	background: transparent; /* Otherwise white in Chrome */

	-webkit-appearance: none;  /* Hides the slider so that custom slider can be made */
}
input[type="range"].f-payment__slider-style:focus {
	outline: none; /* Removes the blue border. */
}
input[type="range"].f-payment__slider-style::-webkit-slider-thumb {
	opacity: 0;

	height: 7.6rem;

	-webkit-appearance: none;
}
input[type="range"].f-payment__slider-style::-ms-track {
	width: 100%;
	cursor: pointer;

	height: 5rem;

	/* Hides the slider so custom styles can be added */
	background: transparent;
	border-color: transparent;
	color: transparent;
}

/* Webkit styling - Chrome/Opera/Safari */
input[type="range"].f-payment__slider-style::-webkit-slider-thumb {
	opacity: 0;

	height: 7.6rem;
	width: 11.6rem;

	margin-top: -1.4rem;

	background: var(--color-grey-light);
	border: 1px solid var(--color-grey);
	/*border-radius: 50%;*/

	cursor: pointer;
}

input[type="range"].f-payment__slider-style::-webkit-slider-runnable-track {
	height: 5rem;
	min-height: 1px;
	width: 100%;

	/*background-color: red;*/

	/*margin: -21% 0 0 0;*/

	/*background: var(--color-grey);*/
	border: none;

	transform: translateX(0rem);

	cursor: pointer;
}

/* Mozilla styling - Firefox */
input[type="range"].f-payment__slider-style::-moz-range-thumb {
	opacity: 0;

	height: 7.6rem;
	width: 1.6rem;

	transform: translateX(0); /* HACK so the track doesn't shine through' */

	cursor: pointer;
}

input[type="range"].f-payment__slider-style::-moz-range-track {
	width: 100%;
	height: 5rem;
	min-height: 1px;

	/*background: var(--color-grey);*/
	border: none;

	transform: translateX(1rem);

	cursor: pointer;
}

/* Microsoft styling - Edge, IE
TODO: mouse doesn't follow thumb exactly */
input[type="range"].f-payment__slider-style::-ms-thumb {
/*	display: none;
*/
	opacity: 0;

	height: 7.6rem;
	width: 1rem;

	transform: translateY(.4rem);

	cursor: pointer;
}

input[type="range"].f-payment__slider-style::-ms-track {
	height: 5rem;
	min-height: 1px;

	margin-left: 1rem;

	cursor: pointer;
}

input[type="range"].f-payment__slider-style::-ms-fill-lower {
	/*background: var(--color-grey);*/

	border: none;
}

input[type="range"].f-payment__slider-style::-ms-fill-upper {
	/*background: var(--color-grey);*/

	border: none;
}

/*

## Donation

With a `f-text-input` field

```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->


	<fieldset class="f-radio-group">
		<div class="f-radio-group__element">
			<input id="rb4" radio-group="amount" type="radio" name="rb" value="500">
			<label for="rb4">$500</label>
		</div>
		<div class="f-radio-group__element">
			<input id="rb5" radio-group="amount" type="radio" name="rb" value="250">
			<label for="rb5">$250</label>
		</div>
		<div class="f-radio-group__element">
			<input id="rb6" radio-group="amount" type="radio" name="rb" value="100">
			<label for="rb6">$100</label>
		</div>
		<div class="f-radio-group__element">
			<input id="rb7" radio-group="amount" type="radio" name="rb" value="50">
			<label for="rb7">$50</label>
		</div>
		<div class="f-radio-group__element">
			<input id="rb8" radio-group="amount" type="radio" name="rb" value="20">
			<label for="rb8">$20</label>
		</div>
		<div class="f-text-input f-radio-group__element">
			<input id="rb9" radio-group="amount" type="radio" name="rb" value="20">
			<label for="rb9">
				<input class="f-text-input__text-field" type="number" placeholder="$10">
			</label>
		</div>
	</fieldset>


</div> <!-- only here for the style guide - should not be used on the website -->
```


## In a grid

With a `f-text-input` field

```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

<section class="f-row f-row_12">
	<div class="f-row__cell-8 f-row__cell-mobile-2"><p>8 cell</p></div>
	<div class="f-row__cell-4 f-row__cell-mobile_push_2 f-row__cell-mobile-8">
		<fieldset class="f-radio-group">
			<div class="f-column f-column_2 f-column_mobile_3">
				<div class="f-radio-group__element">
					<input id="rb10" radio-group="amount2" type="radio" name="rb" value="500">
					<label for="rb10">$500</label>
				</div>
				<div class="f-radio-group__element">
					<input id="rb11" radio-group="amount2" type="radio" name="rb" value="250">
					<label for="rb11">$250</label>
				</div>
				<div class="f-radio-group__element">
					<input id="rb12" radio-group="amount2" type="radio" name="rb" value="100">
					<label for="rb12">$100</label>
				</div>
				<div class="f-radio-group__element">
					<input id="rb13" radio-group="amount2" type="radio" name="rb" value="50">
					<label for="rb13">$50</label>
				</div>
				<div class="f-radio-group__element">
					<input id="rb14" radio-group="amount2" type="radio" name="rb" value="20">
					<label for="rb14">$20</label>
				</div>
				<div class="f-text-input f-radio-group__element">
					<input id="rb15" radio-group="amount2" type="radio" name="rb" value="20">
					<label for="rb15">
						<input class="f-text-input__text-field" type="number" placeholder="$10">
					</label>
				</div>
			</div><!-- /f-column -->
		</fieldset>
	</div>
</section>
</div>
*/

/* RECEIPT */
/*I'm expanding our BEM here. Three _ _ _ is now stuff we don't write ourselves ??? Maybe ??? */

.f-payment-form___tab {
  width: 42rem;
  margin: 0 auto auto -3rem;
  height: 15px;
  margin-bottom: -5px;
  background-color: #fff;
  box-shadow: inset 0 0 35px #ddd;
  border-radius: 50px;

}

.f-payment-form___pseudo {
  position: relative;
}

.f-payment-form___panel-dark {
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  box-shadow: 0 5px 15px #ccc;
}

.f-payment-form___container-print {
  padding: 0;
}

.f-payment-form___container-print .f-payment-form___panel-body {
	overflow: hidden;
	padding: 4rem;
	margin-bottom: 15.4rem;
}

@media (--viewport-mobile) {
	.f-payment__footer-text {
		color: var(--color-grey);
		font-size: 2.4rem;
		font-weight: 100;
		line-height: 3.2rem;
	 }

	 .f-payment__range-label {
        margin: 0;
		line-height: 4.2rem;
	 }

	 .f-payment__slider { position: relative; }

	input[type="range"].f-payment__slider-style {
		width: 100%; /* Specific width is required for Firefox. */
		height: 2rem;
		background: transparent; /* Otherwise white in Chrome */

		-webkit-appearance: none;  /* Hides the slider so that custom slider can be made */
		overflow: hidden;
		padding: 4rem;
	 }
 }

/*
##EXAMPLE 1

<button class="f-button-basic">Toggle modal window</button>
```
 <div class="f-modal f-modal_visible">
  <div class="f-modal__content">
	<div class="f-content">
		<h1 class="f-left-heading">Rebuild Kobanê</h1>
		<p class="f-text__text">
		Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis aliquid hic ex sed possimus perspiciatis ullam nostrum, qui eligendi officia eaque suscipit dolores enim repellendus mollitia, itaque blanditiis dolorem vitae.
		</p>
	</div>
 </div>
</div>
```

<script>
	// demo script
	document.querySelector(".f-button-basic").addEventListener("click", function(e) {
		document.querySelector(".f-modal").classList.toggle("f-modal_visible")
	})
</script>
*/

@import "../variables/variables.css";

.f-modal {
	position: fixed;
	padding-top: 10rem;

	z-index: 100;
	top: -100vh;
	left: 0;
	width: 100%;
	height: 100%;

/*	background-color: var(--color-black-transparent);*/
	background-color: var(--color-grey-light-warm);

	transition: top 250ms ease, opacity 250ms ease;
	will-change: top, opacity;

	opacity: 0;
	pointer-events: none;
}

.f-modal_visible {
	top:0;

	background-color: var(--color-black-transparent);

	opacity: 1;
	pointer-events: auto;
}

.f-modal__content {
	padding-bottom: var(--gutter-width);
	position: absolute;
	/*top: 30%;*/
	/*left: 50%;*/

	max-height: 150rem;
	width: 64.5rem;

	/*transform: translate(-50%, -50%);*/

	/*pointer-events: all;*/
/*	overflow:auto;*/
}

.f-modal__url {
	display: inline;
	font-size: 2.7rem;
	color: #909192;
	padding-right: 2.4rem !important;
	padding-left: 3.5rem !important;
	padding: 3.5px;
	/*border: solid 2px #9BA0A5;*/
	display: inline;
	background-color: white;
	border-radius: 2rem;
}

.f-modal__url-input {
	/*border: solid 2px;*/
	border-left: none;
	padding-top: 9.9px;

	/*border-color: #9ba0a4;*/

	font-size: 1.8rem;
	height: 31px;
	color: #757575;
	background-color: #ddd;
	margin-left: -2.4rem;
	/* width: auto; */
	max-width: 28rem;
	border-radius: 2px;
}

.f-modal__url::before {
	margin-left: -1.7rem;
	margin-right: 1.2rem;
	/*content: '🔒';*/
	font-family: roboto;
	color: #ad1c1a;

}

.f-modal__input {
	color: #757575;
	width: 13.8rem;
	min-width: 27%;
	max-width: fit-content;
	background-color: #dddddd;
}

.ui-icon {
	color: red;
	background-color: red;
}

.f-modal__submit {
	padding: .5rem;
	position: absolute;
	width: 20rem;
	font-size:  2.3rem;
	height: 40px;
	/*right: 10.7rem;*/

	color: white;
	background-color: #ad1c1a;
}

img.ql-container:after {
    content: "";

    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #dddddd;
}

@media screen and (--viewport-mobile) {

	input#endDate {
		min-width: 41%;

	}

	img:after {
		display: none;
	}

	.f-modal_visible {
		background-color: var(--color-grey-light-warm);
	}

	.f-modal__url {
		padding-left: .8rem !important;
	}

	.f-modal__url-input {
		height: 4.6rem;
		padding-top: 1.7rem;
		zoom:  1.7;
		width: 15.1rem;
	}
	.f-modal__url-input, input:focus {
		/*outline: none;*/
		max-height: 40px;
		padding-top: 1.2rem;
		/*border: 2px solid;*/
	}
	.f-modal__submit {
		height: 40px;
		right: 4rem;
		width: 22%;
	}
	.f-modal__input:focus {
		font-size: 2.3rem;
		/*height: 12.2rem !important;*/
		outline-offset: 0;
		/*background-color: green;*/
	}

	input.f-modal__input {
		/*background-color: pink;*/
		height: 40px;
	}

	input.f-modal__submit:focus {
		/*background-color: pink;*/
		height: 40px;
		border: none;

	}

	.f-modal__input {
		font-size: 2.3rem;
		width: 14rem;
		/*background-color: pink;*/
	}

	::-webkit-calendar-picker-indicator{
	    margin-left: 0px;
	}
}

/*.f-modal_autoclosable {}*/

/*
### Bold

```
<p style="font-weight:700;color:white;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris pellentesque ex lorem, id cursus sapien consectetur at. Pellentesque vulputate, nibh.</p>
<p style="font-style:italic;font-weight:700;color:white;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris pellentesque ex lorem, id cursus sapien consectetur at. Pellentesque vulputate, nibh.</p>
```

### Normal

```
<p style="font-weight:400;color:white;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris pellentesque ex lorem, id cursus sapien consectetur at. Pellentesque vulputate, nibh.</p>
<p style="font-style:italic;font-weight:400;color:white;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris pellentesque ex lorem, id cursus sapien consectetur at. Pellentesque vulputate, nibh.</p>
```

### Light

```
<p style="font-weight:300;color:white;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris pellentesque ex lorem, id cursus sapien consectetur at. Pellentesque vulputate, nibh.</p>
<p style="font-style:italic;font-weight:300;color:white;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris pellentesque ex lorem, id cursus sapien consectetur at. Pellentesque vulputate, nibh.</p>
```
*/
@font-face {
  font-family: 'roboto';
  src: local('Roboto Bold'),
       local('Roboto-Bold'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/roboto-bold.woff2) format('woff2'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/roboto-bold.woff) format('woff'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/Roboto-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'roboto';
  src: local('Roboto Bold Italic'),
       local('Roboto-BoldItalic'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/roboto-bolditalic.woff2) format('woff2'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/roboto-bolditalic.woff) format('woff'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/Roboto-BoldItalic.ttf) format('truetype');
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'roboto';
  src: local('Roboto'),
       local('Roboto-Regular'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/roboto-regular.woff2) format('woff2'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/roboto-regular.woff) format('woff'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/Roboto-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'roboto';
  src: local('Roboto Italic'),
       local('Roboto-Italic'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/roboto-regularitalic.woff2) format('woff2'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/roboto-regularitalic.woff) format('woff'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/Roboto-RegularItalic.ttf) format('truetype');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'roboto';
  src: local('Roboto Light'),
       local('Roboto-Light'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/roboto-light.woff2) format('woff2'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/roboto-light.woff) format('woff'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/Roboto-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'roboto';
  src: local('Roboto Light Italic'),
       local('Roboto-LightItalic'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/roboto-lightitalic.woff2) format('woff2'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/roboto-lightitalic.woff) format('woff'),
       url(https://firefund-assets.s3.amazonaws.com/fonts/Roboto-LightItalic.ttf) format('truetype');
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

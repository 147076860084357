/*

A heading with the background reaching the left side of its container,

If the container isn't `f-content`', then the heading will *leak* out.

<pre>
BLOCK f-left-heading
	MODIFIER	f-left-heading_big
	MODIFIER 	f-left-heading_black
</pre>


## f-left-heading - container with padding
```
<div class="f-content">

	<!-- this should be used on the website -->
	<h1 class="f-left-heading">Rebuild Kobanê</h1>
	<!-- this should be used on the website -->

</div>
```


## f-left-heading - leaking out of container

If the container isn't `f-content`', then the heading will *leak* out.

```
<div class="kalei-background-white"><!-- only here for the style guide - should not be used on the website -->

	<!-- this should be used on the website -->
	<h1 class="f-left-heading">Rebuild Kobanê</h1>
	<!-- this should be used on the website -->

</div><!-- only here for the style guide - should not be used on the website -->
```

## .f-left-heading_big - container with padding
```
<div class="f-content">
	<!-- this should be used on the website -->
	<h1 class="f-left-heading f-left-heading_big">About us</h1>
	<!-- this should be used on the website -->
</div>
```


## .f-left-heading_big - leaking out of container
```
<div class="kalei-background-white"><!-- only here for the style guide - should not be used on the website -->

	<!-- this should be used on the website -->
	<h1 class="f-left-heading f-left-heading_big">About us</h1>
	<!-- this should be used on the website -->

</div><!-- only here for the style guide - should not be used on the website -->
```
*/

@import "../variables/variables.css";

.f-left-heading {
	display: inline-flex;

	/* TODO: fix off by 1px error as seen on thumbnails (for now just add -1px) */
	margin: 4rem 0 2.8rem calc(-1 * var(--gutter-width) - 1px);
	padding: .8rem 1.93rem .8rem var(--gutter-width);

	color: var(--color-white);
	font-size: 3.0rem;
	font-weight: normal;

	background-color: var(--color-black);
}
[dir="rtl"] .f-left-heading {
	margin: 4rem -3.4rem 2.8rem 0;
    padding: .8rem var(--gutter-width) .8rem 1.93rem;
}

.f-left-heading_big {
	display: inline-flex;

	padding: .6rem 1.93rem 1.2rem var(--gutter-width);

	color: var(--color-white);
	font-size: 6.6rem;
	line-height: 0.9;

	background-color: var(--color-black);
}

.f-left-heading_black {
	background-color: transparent;
	margin-top: 0;
	margin-bottom: -1.3rem;
}

.f-left-heading_white-card {
	margin: 0;

	color: var(--color-black);
	font-size: 6rem;
	font-weight: lighter;

	background-color: var(--color-white);
}

.f-left-heading_black-card {
	margin-top: 0;

	color: var(--color-white);
	font-size: 6rem;
	font-weight: lighter;

	background-color: var(--color-black);
}

@media (--viewport-mobile) {
	.f-left-heading {
		width: 100%;
/*		margin: 0;
*/	}
	.f-left-heading_history {
		padding: 3.4rem 0 1.7rem var(--gutter-width);
	}
}

#main > div.ql-editor > h1 {
	display: table;

	margin: 0rem 0 2.8rem calc(var(--gutter-width) * -1);
	padding: .8rem 1.93rem .8rem 6.9rem;

	color: var(--color-white);
	font-size: 3.0rem;
	font-weight: normal;

	background-color: var(--color-black);
}


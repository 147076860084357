/*
# f-list

<pre>
BLOCK f-list
MODIFIER f-list_letters
MODIFIER f-list_halfcircle
		ELEMENT f-list__milestones
</pre>

Karl wrote `f-list__milestones`, I have no idea where that is used ~Jon


## Unordered list

A bullet list.

```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

	<!-- this should be used on the website -->
	<ul class="f-list">
		<li>The unordered one! Lets make a loooooong sentence and se what the fuck is happining up in here! Double that shit up motherfucker yeah biiiitch</li>
		<li>The unordered second! And this one is shorty! </li>
		<li>The unordered one! Lets make a loooooong sentence and se what the fuck is happining up in here! Double that shit up motherfucker yeah biiiitch</li>
	</ul>
	<!-- this should be used on the website -->

</div>
```

## Ordered list
A number list.
```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

	<!-- this should be used on the website -->
	<ol class="f-list">
		<li>The ordered one!</li>
		<li>The ordered second one!!!  Lets make a loooooong sentence and se what the fuck is happining up in here! Double that shit up motherfucker yeah biiiitch</li>
		<li>The ordered one!</li>
	</ol>
	<!-- this should be used on the website -->

	<!-- this should be used on the website -->
	<ol class="f-list f-list_halfcircle">
		<li>The ordered one!</li>
		<li>The ordered second one!!!  Lets make a loooooong sentence and se what the fuck is happining up in here! Double that shit up motherfucker yeah biiiitch</li>
		<li>The ordered one!</li>
	</ol>
	<!-- this should be used on the website -->

</div>
```

## Ordered list with letters
A list with lower-alpha.
```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

	<!-- this should be used on the website -->
	<ol class="f-list f-list_letters">
		<li>The ordered one!</li>
		<li>The ordered second one!!!  Lets make a loooooong sentence and se what the fuck is happining up in here! Double that shit up motherfucker yeah biiiitch</li>
		<li>The ordered one!</li>
	</ol>
	<!-- this should be used on the website -->

</div>
```
*/

@import "../variables/variables.css";

.f-list {
	--font-size: 1.6rem;

	display: table;
	margin: 0 0 1.4rem;
	padding: 0;

	list-style: none;
	counter-reset: f-list;
}

.f-list strong {
	font-weight: bold;
}

.f-list li {
	display: table-row;
	height: 3rem;

	color: var(--color-grey-dark);
	font-size: var(--font-size);
	font-weight: lighter;
	line-height: 1.2;
}

.f-list li::before {
	display: table-cell;
	position: relative;
	top: 0.9rem; /* set the vertical position of the bullet if necessary. Make sure to use rem or % */

	padding-right: 1.25rem; /* give the bullet some padding from the text Make sure to use rem*/
	padding-left: 0;
	content: "\2022"; /* /2022 is the numerical unicode for &bull; */

	color: var(--color-red);
	font-size: 3.8rem; /* set the font-size of the bullet if necessary. Make sure to use rem or %*/
	line-height: 0; /* should be zero or the bullets will add vertical space to the li's */
}

ol.f-list li::before {
	top: 0rem;

	font-size: var(--font-size);

	counter-increment: f-list;
	content: counter(f-list) ".";
}

ol.f-list_letters li::before {
	content: counter(f-list, lower-alpha) ")";
}

ol.f-list_halfcircle li::before {
	content: counter(f-list) ")";
}

@media screen and (--viewport-mobile) {
	.f-list li {
		font-size: 2.5rem;
		line-height: 3.3rem;
	}

	ol.f-list li:before {
		font-size: 2.2rem;
	}
}

/* TODO: document f-list__milestones Karl */
.f-list__milestones {
	list-style: none;
	padding-left: 2px;

	font-size: 2rem;
	font-weight: bold;
	color: var(--color-grey-dark);

	line-height: 3rem;
}

/*
## table

This is, currently, a very minimalistic styling of the HTML `<table>` element.
You must use the `table--default` modifier to see anything. This is made, so
that we **do not**, get into an issue were we have to overwrite styles all the
time, because we started out with styles, that can not be used in all cases.

So far, this table is only used on https://www.firefund.net/druckpresscollective

<pre>
BLOCK table
MODIFIER table--default
    ELEMENT table__no-break
    ELEMENT table__value
</pre>

```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

	<!-- this should be used on the website -->

<table class="table table--default">
	<tbody class="table__body">
			<tr>
					<td>Roland Praktika Offset press, 48x65cm print dimensions</td>
					<td class="table__value">2500 €</td>
			</tr>
			<tr>
					<td>Adast Maxima MM80-3 paper guillotine</td>
					<td class="table__value">1500 €</td>
			</tr>
			<tr>
					<td>Electrical installations</td>
					<td class="table__value">2000 €</td>
			</tr>
			<tr>
					<td>Moving and installing machinery</td>
					<td class="table__value">1500 €</td>
			</tr>
			<tr>
					<td>Removing glass panels to allow machinery to enter the space</td>
					<td class="table__value">500 €</td>
			</tr>
			<tr>
					<td>Floor support</td>
					<td class="table__value">250 €</td>
			</tr>
			<tr>
					<td>Maintenance work</td>
					<td class="table__value">350 €</td>
			</tr>
			<tr>
					<td>Rent deposit and first months’ rent</td>
					<td class="table__value">890 €</td>
			</tr>
	</tbody>
</table>

	<!-- this should be used on the website -->

</div>
```

## Example with `table__no-break` element.

```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

	<!-- this should be used on the website -->
<table class="table table--default">
		<tbody class="table__body">
				<tr>
						<td>Ρολό ασφαλείας</td>
						<td class="table__no-break">έως</td>
						<td class="table__value">1500 €</td>
				</tr>
				<tr>
						<td>Διπλωτική και συραπτική μηχανή</td>
						<td class="table__no-break">έως</td>
						<td class="table__value">1500 €</td>
				</tr>
				<tr>
						<td>Πρέσα συμπίεσης</td>
						<td class="table__no-break">έως</td>
						<td class="table__value">250 €</td>
				</tr>
				<tr>
						<td>Εμφανιστήριο τσίγκων</td>
						<td class="table__no-break">έως</td>
						<td class="table__value">700 €</td>
				</tr>
				<tr>
						<td>Διατρητικό τσίγκου</td>
						<td class="table__no-break">έως</td>
						<td class="table__value">300 €</td>
				</tr>
				<tr>
						<td>Αναλώσιμα εκτύπωσης (μελάνια, χαρτιά, χημικά)</td>
						<td class="table__no-break">έως</td>
						<td class="table__value">500 €</td>
				</tr>
				<tr>
						<td>Πάγκοι και ράφια για την αποθήκευση των υλικών</td>
						<td class="table__no-break">έως</td>
						<td class="table__value">200 €</td>
				</tr>
				<tr>
						<td>H/Y με δυνατότητες προγραμμάτων επεξεργασίας και ελέγχου αρχείων</td>
						<td class="table__no-break">έως</td>
						<td class="table__value">600 €</td>
				</tr>
				<tr>
						<td>Τοποθέτηση πυρασφάλειας</td>
						<td class="table__no-break">έως</td>
						<td class="table__value">400 €</td>
				</tr>
				<tr>
						<td>Θέρμανση</td>
						<td class="table__no-break">έως</td>
						<td class="table__value">1000 €</td>
				</tr>
		</tbody>
</table>
```
*/

@import "../variables/variables.css";

.table {
	margin-bottom: var(--gutter-width);
	width: 100%;
}

.table--default .table__body {
	color: var(--color-grey-dark);
	font-size: 2.3rem;
	font-weight: 300;
	line-height: var(--gutter-width);
	text-rendering: optimizeLegibility;
	vertical-align: top;
}

.table--default .table__no-break {
	white-space: nowrap;
}

.table--default .table__value {
	font-weight: 700;
	text-align: right;
	white-space: nowrap;
}

@media (min-width: 641px) {
	.table--default .table__body {
			font-size: 1.6rem;
			line-height: 1.82rem;
	}
}

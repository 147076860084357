/*
Our media element. Can be an image or video.

<pre>
BLOCK f-media
MODIFIER f-media--no-caption
	ELEMENT f-media__media
	ELEMENT f-media__caption
</pre>


## Standard image
```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

<!-- this should be used on the website -->
<figure class="f-media">
	<img class="f-media__media" src="https://firefund-assets.s3.amazonaws.com/demo_pictures/04.jpg">
	<figcaption class="f-media__caption">Caption text for da media</figcaption>
</figure>
<!-- this should be used on the website -->

</div> <!-- only here for the style guide - should not be used on the website -->
```

## Images in f-row
```
<div class="f-content">

	<section class="f-row f-row_6">
		<div class="f-row__cell-2 f-row__cell-mobile">

			<div class="f-text">
				<h2 class="f-text__heading">Heading explaining what the text will be about</h2>

				<p class="f-text__text">
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
					Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
					necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
					autem doloremque voluptate fugiat.
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
					Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
					necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
					autem doloremque voluptate fugiat.
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
					Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
					necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
					autem doloremque voluptate fugiat.
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
					Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
					necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
					autem doloremque voluptate fugiat.
				</p>
				<p class="f-text__text">
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
					Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
					necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
					autem doloremque voluptate fugiat.
				</p>
			</div><!-- /f-text -->

			<!-- this should be used on the website -->
			<figure class="f-media">
				<img class="f-media__media" src="https://firefund-assets.s3.amazonaws.com/demo_pictures/10.jpg">
			</figure>
			<!-- this should be used on the website -->

		</div><!-- /f-row__cell-2 -->

		<div class="f-row__cell-4 f-row__cell-mobile">

			<!-- this should be used on the website -->
			<figure class="f-media">
				<img class="f-media__media" src="https://firefund-assets.s3.amazonaws.com/demo_pictures/17.jpg">
				<figcaption class="f-media__caption">Fuck ISIS!</figcaption>
			</figure>
			<!-- this should be used on the website -->

			<div class="f-row f-row_6">
				<div class="f-row__cell-3 f-row__cell-mobile">

					<div class="f-text">
						<p class="f-text__text">
							Lorem ipsum dolor sit amet, consectetur adipisicing elit.
							Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
							necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
							autem doloremque voluptate fugiat.
							Lorem ipsum dolor sit amet, consectetur adipisicing elit.
							Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
							necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
							autem doloremque voluptate fugiat.
							Lorem ipsum dolor sit amet, consectetur adipisicing elit.
							Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
							necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
							autem doloremque voluptate fugiat.
							Lorem ipsum dolor sit amet, consectetur adipisicing elit.
							Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
							necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
							autem doloremque voluptate fugiat.
						</p>
					</div><!-- /f-text -->
				</div><!-- /f-row__cell-3 -->

				<div class="f-row__cell-3 f-row__cell-mobile">

					<div class="f-text">
						<p class="f-text__text">
							Lorem ipsum dolor sit amet, consectetur adipisicing elit.
							Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
							necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
							autem doloremque voluptate fugiat.
							Lorem ipsum dolor sit amet, consectetur adipisicing elit.
						</p>
						<h2 class="f-text__heading">Here and back again</h2>
						<p class="f-text__text">
							Lorem ipsum dolor sit amet, consectetur adipisicing elit.
							Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
							necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
							autem doloremque voluptate fugiat.
							Lorem ipsum dolor sit amet, consectetur adipisicing elit.
							Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
							necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
							autem doloremque voluptate fugiat.
						</p>
					</div><!-- /f-text -->

				</div><!-- /f-row__cell-3 -->
			</div><!-- /f-row -->

		</div><!-- /f-row__cell-4 -->
	</section><!-- /f-row -->

</div><!-- /f-content -->
```

## Standard video
```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

<!-- this should be used on the website -->
<figure class="f-media">
	<iframe class="f-media__media" height="300" src="http://www.youtube.com/embed/vaf13ZhmFb8?rel=0" allowfullscreen="" frameborder="0"></iframe>
	<figcaption class="f-media__caption">Caption text for da media</figcaption>
</figure>
<!-- this should be used on the website -->

</div> <!-- only here for the style guide - should not be used on the website -->
```

## Video in f-row
```
<div class="f-content">

	<div class="f-row f-row_6">
		<div class="f-row__cell">

		<h1 class="f-left-heading">Rebuild Kobanê</h1>

		<!-- this should be used on the website -->
		<figure class="f-media">
			<iframe class="f-media__media" height="450" src="http://www.youtube.com/embed/vaf13ZhmFb8?rel=0" allowfullscreen="" frameborder="0"></iframe>
		</figure>
		<!-- this should be used on the website -->

		<p class="f-cuff-text">This is a cuff text Lorem ipsum
			dolor sit amet, consectetur adipisicing elit. Debitis amet quia
			illo libero in totam, nihil, molestiae optio, officia dolores
			ducimus! Quam ullam labore eum optio laudantium tempora est aspernatur.
		</p>

		<section class="f-text">
			<h2 class="f-text__heading">Heading explaining what the text will be about</h2>
			<p class="f-text__text">
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
					Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
					necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
					autem doloremque voluptate fugiat.
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
					Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
					necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
					autem doloremque voluptate fugiat.
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
					Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
					necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
					autem doloremque voluptate fugiat.
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
					Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
					necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
					autem doloremque voluptate fugiat.
			</p>
			<p class="f-text__text">
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
					Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
					necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
					autem doloremque voluptate fugiat.
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
					Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
					necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
					autem doloremque voluptate fugiat.
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
					Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
					necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
					autem doloremque voluptate fugiat.
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
					Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
					necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
					autem doloremque voluptate fugiat.
			</p>
		</section>

		</div><!-- /f-row__cell -->
	</div><!-- /f-row -->

</div> <!-- /f-content -->
```

## Media without caption

Using the `f-media--no-caption` modifier on `f-media` makes sure that the spacing below a media, without caption text, is correct.

```
<div class="kalei-background-white" style="max-width: 40rem;"> <!-- only here for the style guide - should not be used on the website -->

<!-- this should be used on the website -->
<figure class="f-media f-media--no-caption">
	<img class="f-media__media" src="https://firefund-assets.s3.amazonaws.com/demo_pictures/17.jpg">
</figure>
<figure class="f-media f-media--no-caption">
	<img class="f-media__media" src="https://firefund-assets.s3.amazonaws.com/demo_pictures/17.jpg">
</figure>
<!-- this should be used on the website -->

</div> <!-- only here for the style guide - should not be used on the website -->
```

*/

@import "../variables/variables.css";

.f-media {
	display: block;
	margin: 0 0 0.65rem;
}
.f-media--no-caption {
	margin-bottom: var(--gutter-width);
}


.f-media__media {
	width:100%;
	max-height: 100%;
}

.f-media__caption {
	margin: .55rem 0 2rem 0;

	color: var(--color-grey);
	font-size: 1.4rem;
	font-style: italic;
	font-weight: 300;
}

.f-media__hero {
	display: block;
	position: absolute;
	left: -11rem;
	top: 18rem;
	height: 100%;

}


.f-media__qr {
	display: block;

	max-width: 100%;
}

.f-media__qr-small {
	display: block;

	max-width: 40%;
}

.f-media_center {
	background-color: var(--color-grey);

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* f-media__hidden is NOT documented */
.f-media__hidden {
	filter: blur(20px);
/*	-webkit-filter: blur(20px);
*/
}

/* f-media__active is NOT documented */
.f-media__active {
	z-index:99;
}

/* @keyframes slidy is NOT documented */
@keyframes slidy {
0% { left: -0%; }
10.3% { left: -0%; }
14.3% { left: -100%; }
30.6% { left: -100%; }
34.6% { left: -200%; }
48% { left: -200%; }
52% { left: -300%; }
75.3% { left: -300%; }
80% { left: -400%; }
100% { left: -400%; }
}

/* div#slider is NOT documented */
div#slider { overflow: hidden; }
div#slider figure img { width: 20%; float: left; }
div#slider figure {
  position: relative;
  width: 500%;
  margin: 0;
  left: 0;
  text-align: left;
  font-size: 0;
  animation: 30s slidy infinite;
}

/* f-media__qr is NOT documented */
@media (--viewport-mobile){
.f-media__qr {
	max-width: 50%;
	margin-left: auto;
	margin-right: auto;
  }
}

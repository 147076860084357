 /*
 Text diplayed after making a donation.
 <pre>
 BLOCK f-postpay-info
    Element f-postpay-
 </pre>

 ## FLEXIBLE FUNDING MODEL
 ```
 <div class="f-postpay-info">
    <ul class="f-postpay-info__text">
        <li>You donated <span>$15.00</span> directly</li>
        <li>You chose a platform cut on <span>$1.50<span></li>
    </ul>
    <ul class="f-postpay-info__text">
        <li>If we reach <span>$70.000</span> in <span>13 days</span>,</li>
        <li>all pledged donations will also be transferred</li>
    </ul>
    <ul class="f-postpay-info__text">
        <li>If the campaign do not reach <span>$70.000</span>,</li>
        <li>then only direct donations wil be transferred.</li>
    </ul>
</div>
 ```
 ## DIRECT FUNDING MODEL
 ```
 <div class="f-postpay-info">
    <ul class="f-postpay-info__text">
        <li>You donated <span>$15.00</span> directly.</li>
        <li>You chose a platform cut on <span>$1.50<span></li>
</div>
 ```
 ## PLEDGED FUNDING MODEL
 ```
 <div class="f-postpay-info">
    <ul class="f-postpay-info__text">
        <li>You pledged to donate <span>$15.00</span> if the campaign reaches the goal.</li>
        <li>You chose a platform cut on <span>$1.50<span></li>
    </ul>
    <ul class="f-postpay-info__text">
        <li>We will only withdraw your donation if we reach <span>$70.000</span> in <span>13 days</span></li>
    </ul>
    <ul class="f-postpay-info__text">
        <li>If the campaign do not reach <span>$70.000</span>,</li>
        <li>no money will be transferred.</li>
    </ul>
</div>
 ```
 */

 .f-postpay-info {
    margin-top: 0px;
    margin-top: 0rem;
    margin-bottom: 128px;
    margin-bottom: 8rem;

    color: hsla(0, 0%, 100%, 1);
    font-size: 23.3px;
    font-size: 1.8rem;
 }

 .f-postpay-info__text {
    margin-top: 0px;
    margin-top: 0rem;
    margin-bottom: 44px;
    margin-bottom: 3rem;
    padding: 0;

    line-height: 48px;
    line-height: 2.2rem;
    font-weight: lighter;
    list-style: none;
 }

  .f-postpay-info__text_signup {
    font-weight: lighter;
    font-size: 32px;
    font-size: 2rem;
 }
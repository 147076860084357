/*
<pre>
BLOCK f-content
MODIFIER   f-content_black
</pre>

## 1 SMALL
```
<div class="f-content">
    <article class="f-text">
        <p class="f-text__text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Libero voluptate eos reprehenderit animi adipisci quibusdam fugiat nam tenetur ea alias tempora, autem, facere. Esse quas facere quaerat explicabo illum molestias!
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Libero voluptate eos reprehenderit animi adipisci quibusdam fugiat nam tenetur ea alias tempora, autem, facere. Esse quas facere quaerat explicabo illum molestias!
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Libero voluptate eos reprehenderit animi adipisci quibusdam fugiat nam tenetur ea alias tempora, autem, facere. Esse quas facere quaerat explicabo illum molestias!
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Libero voluptate eos reprehenderit animi adipisci quibusdam fugiat nam tenetur ea alias tempora, autem, facere. Esse quas facere quaerat explicabo illum molestias!
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Libero voluptate eos reprehenderit animi adipisci quibusdam fugiat nam tenetur ea alias tempora, autem, facere. Esse quas facere quaerat explicabo illum molestias!
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Libero voluptate eos reprehenderit animi adipisci quibusdam fugiat nam tenetur ea alias tempora, autem, facere. Esse quas facere quaerat explicabo illum molestias!
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Libero voluptate eos reprehenderit animi adipisci quibusdam fugiat nam tenetur ea alias tempora, autem, facere. Esse quas facere quaerat explicabo illum molestias!
        </p>
    </article>
</div>
```

## Eksempel 2
```
<div class="f-content">
<h1 class="f-left-heading">Rebuild Kobanê</h1>
<p class="f-cuff-text">
    This is a cuff text Lorem ipsum
    dolor sit amet, consectetur adipisicing elit. <span>Debitis amet quia</span>
    illo libero in totam, nihil, molestiae optio, officia dolores
    ducimus! Quam ullam labore <a href="">eum optio laudantium tempora est aspernatur</a>.
</p>

</div>
```

## Eksempel 3
```
<div class="f-content">
<ol class="f-list">
    <li>The ordered one!</li>
    <li>The ordered second one!!!  Lets make a loooooong sentence and se what the fuck is happining up in here! Double that shit up motherfucker yeah biiiitch</li>
    <li>The ordered one!</li>
</ol>
<div class="f-text-input f-text-input_multiline">
    <label class="f-text-input__label">Message
        <textarea class="f-text-input__text-field" rows="6" placeholder="This is OpenPGP encrypted using the OpenPGP.js library"></textarea>
    </label>
</div>
<h2 class="f-one-liner f-one-liner">This is probably another link sentence.</h2>
</div>
```

## Eksample 4
```
<div class="f-content">
    <ol class="f-list">
        <li>The ordered one!</li>
        <li>The ordered second one!!!  Lets make a loooooong sentence and se what the fuck is happining up in here! Double that shit up motherfucker yeah biiiitch</li>
        <li>The ordered one!</li>
    </ol>
    <div class="f-text-input f-text-input_multiline">
        <label class="f-text-input__label">Message
            <textarea class="f-text-input__text-field" rows="6" placeholder="This is OpenPGP encrypted using the OpenPGP.js library"></textarea>
        </label>
    </div>
    <h2 class="f-one-liner f-one-liner">This is probably another link sentence.</h2>

    <section class="f-text">
        <h2 class="f-text__heading">Heading explaining what the text will be about</h2>

        <p class="f-text__text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur <a href"">excepturi</a> et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
        </p>

        <p class="f-text__text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, <a href"">tenetur excepturi</a> et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, <a href"">tenetur excepturi</a> et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            <a href="">Lorem ipsum dolor</a> sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque <a href="">voluptate fugiat.</a>
        </p>
    </section>

</div>
```
*/

@import "../variables/variables.css";

.f-content {
    padding: 0 var(--gutter-width);

    background-color: var(--color-white);
    border-radius: 0.3rem;

    lost-center: var(--page-max-width);
}

.f-content_black {
    padding: 0 var(--gutter-width);

    background-color: var(--color-black);
    border-radius: 0.3rem;

    lost-center: var(--page-max-width);
}

.f-content_payment {
    background-color: var(--color-grey-light-warm);
}


@media (--viewport-mobile) {
    .f-content_frontpage {
        padding: 0;
        background-color: var(--color-grey-light-extra);
        border-radius: 0rem;
    }

    .f-content_payment {
        padding: 0;
        /*background-color: var(--color-grey-light-warm);*/
        border-radius: 0rem;
        margin-left: -10rem;
        margin-right: -10rem;
    }


}


/*
A container that put 3 buttons per row with equal space between them, where the first and last button are justified to the edge.
Goes into two columns on mobile.

<pre>
BLOCK f-button-group
MODIFIER f-button-group_2
MODIFIER f-button-group_3
MODIFIER f-button-group_mobile_2
    ELEMENT f-button-group__button
    MODIFIER f-button-group__button_selected
</pre>

## What I would like:

In the following example, we have two hover effects. One on the bottom line of buttons (Contact, Who we are, Dank memes), and another on the top line (Terms, Blog, Manifesto).
Can we mix them? Could we use the bottom effect as a hovereffect, and then 'on click' use the top 'sweep' effect?

I think it would look awesome!
The effects from the Hover.css file is called: 'Underline Reveal' and 'Sweep to top'.

## Default
```
<div class="kalei-background-white"><!-- only here for the style guide - should not be used on the website -->


<!-- this should be used on the website -->
<section class="f-button-group f-button-group_3 f-button-group_mobile_2">
	<button class="f-button-group__button">Terms</button>
	<button class="f-button-group__button">Blog</button>
	<button class="f-button-group__button">Manifesto</button>
	<button class="f-button-group__button">Contact</button>
	<button class="f-button-group__button">Who we are</button>
	<button class="f-button-group__button">Dank memes</button>
</section>
<!-- this should be used on the website -->


</div><!-- only here for the style guide - should not be used on the website -->
```

## Reverse **DON'T USE!**
All the reverse and vertical stuff won't work without changing `lost` core.
I'm keeping these examples as a reminder but **DON'T USE THEM!**

```
<div class="kalei-background-white">

<!-- this should be used on the website -->
<section class="f-button-group f-button-group_reverse">
	<button class="f-button-group__button">Terms</button>
	<button class="f-button-group__button">Blog</button>
	<button class="f-button-group__button">Manifesto</button>
	<button class="f-button-group__button">Contact</button>
	<button class="f-button-group__button">Who we are</button>
	<button class="f-button-group__button">Dank memes</button>
</section>
<!-- this should be used on the website -->

</div>
```

## Vertical **DON'T USE!**
```
<div class="kalei-background-white">

<!-- this should be used on the website -->
<section class="f-button-group f-button-group_vertical">
	<button class="f-button-group__button">Terms</button>
	<button class="f-button-group__button">Blog</button>
	<button class="f-button-group__button">Manifesto</button>
	<button class="f-button-group__button">Contact</button>
	<button class="f-button-group__button">Who we are</button>
	<button class="f-button-group__button">Dank memes</button>
</section<!-- this should be used on the website -->

</div>
```

## Vertical Reverse **DON'T USE!**
```
<div class="kalei-background-white">

<!-- this should be used on the website -->
<section class="f-button-group f-button-group_vertical_reverse">
	<button class="f-button-group__button">Terms</button>
	<button class="f-button-group__button">Blog</button>
	<button class="f-button-group__button">Manifesto</button>
	<button class="f-button-group__button">Contact</button>
	<button class="f-button-group__button">Who we are</button>
	<button class="f-button-group__button">Dank memes</button>
</section>
<!-- this should be used on the website -->

</div>
```
*/

@import "../variables/variables.css";


/* BLOCK */
.f-button-group {
	min-width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
/* MODIFIER */
.f-button-group_2 > .f-button-group__button {
	flex-basis: calc(50% - 2.26667rem);
	max-width: calc(50% - 2.26667rem);
	width: calc(50% - 2.26667rem);
}
.f-button-group_3 > .f-button-group__button {
	flex-basis: calc(33.3% - 2.26667rem);
	max-width: calc(33.3% - 2.26667rem);
	width: calc(33.3% - 2.26667rem);
}
@media (--viewport-mobile) {
	.f-button-group_mobile_2 > .f-button-group__button {
		flex-basis: calc(49.95% - 1.7rem);
		max-width: calc(49.95% - 1.7rem);
		width: calc(49.95% - 1.7rem);
	}
}
/* .f-button-group > .f-column {
	justify-content: space-between;
}
.f-button-group_vertical > .f-column {
	flex-direction: column;
}
.f-button-group_vertical_reverse > .f-column {
	flex-direction: column-reverse;
	flex-wrap: wrap-reverse;
}
.f-button-group_reverse > .f-column {
	flex-direction: row-reverse;
	flex-wrap: wrap-reverse;
} */

/* ELEMENT */
.f-button-group__button {
	margin-bottom: 3.2rem;

	color: inherit;
	font-size: 3rem;
	font-weight: lighter;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;

	transition-property: color, background-size, background-position; /*background;*/
	transition-duration: 200ms;
	transition-timing-function: ease-out;

	background: linear-gradient(to top, black 50%, white 50%);
	background-size: 100% 200%;
	background-position:left top;
	border: .2rem solid var(--color-black);
	border-radius: 0.5rem;

	cursor: pointer;
	outline: none;
	overflow: hidden;
}

/*For soft-soft launch. TODO: BEM structure*/
.f-button-group__header {
	padding-right: 1.2rem;

	font-weight: lighter;
	font-size: 3rem;

}

.f-button-group__cuff {
	display: block;

	font-weight: lighter;
	font-size: 1.6rem;

	height: 15rem;
}

.f-button-group__translate .f-button-group__button {
	border: none;
}
@media (--viewport-mobile) {
	.f-button-group__translate {
		padding: 0 var(--gutter-width, 3.4rem) 0 var(--gutter-width, 3.4rem);

		background-color: white;
	}
	.f-button-group__translate .f-button-group__button {
		border: .2rem solid var(--color-black);
	}
}

/* .f-button-group__button:hover {
	background-position: left 13%;
} */
.f-button-group__button:active, .f-button-group__button_selected {
	color: var(--color-white);
	background-position: left 90%;
	background-size: 100% 300%; /*KLUDGE: background height scaled bigger because some of the white background shows */
}

.f-button-group__button_selected {
	background-color: black !important;
}

.f-button-group__button_selected:active {
	background-color: pink !important;
}

.f-button-group__button:active {
	background-color: black !important;
}


/*

Made by Karl - adopted by Jon

Used on https://www.firefund.net/nbk2022

<p class="f-attention-box">
	United we stand, divided we fall.
	<br>FIGHT FORTRESS EUROPE! NO BORDER, NO NATION!
	<br>FOR THE FREEDOM OF MOVEMENT FOR EVERYBODY AND EVERYWHERE
</p>
*/

@import "../variables/variables.css";

.f-attention-box {
	margin: .33rem .33rem 2rem;
	padding: 1.33rem;

	border: solid;
	background-color: var(--color-black);

	color: var(--color-white);
	text-align: center;
}

@media screen and (--viewport-mobile) {
	.f-attention-box {
		font-size: 2.6rem;
		line-height: 3.12rem;
	}
}


	/*animation: blink .5s step-end infinite alternate;*/

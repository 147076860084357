/*
A heading with the background reaching the right side of its container,
If the container isn't `f-content`, then the heading will *leak* out.
<pre>
BLOCK f-right-heading
</pre>

## Container with padding
```
<div class="kalei-background-white"><!-- only here for the style guide - should not be used on the website -->
	<div class="f-content">

<!-- this should be used on the website -->
	<h1 class="f-right-heading">About the FUCK</h1>
<!-- this should be used on the website -->

	</div> <!-- only here for the style guide - should not be used on the website -->
</div>
```

## Leaking out of container
```
<div class="kalei-background-white"><!-- only here for the style guide - should not be used on the website -->

<!-- this should be used on the website -->
	<h1 class="f-right-heading">About the FUCK</h1>
<!-- this should be used on the website -->

</div><!-- only here for the style guide - should not be used on the website -->
```
*/

@import "../variables/variables.css";

.f-right-heading {
	margin: 0 calc(var(--gutter-width) * -1) 2.8rem 0;
	padding: .8rem var(--gutter-width) .8rem 1.93rem;

	color: var(--color-white);
	font-size: 2.8rem;
	font-weight: normal;

	background-color: var(--color-black);
}
/*
[dir="rtl"] .f-right-heading {
}
*/

#about > div.ql-editor > h1 {
	margin: 0 calc(var(--gutter-width) * -1) 2.8rem 1.2rem;
	padding: .8rem var(--gutter-width) .8rem 1.93rem;
	width: 101.1%;

	color: var(--color-white);
	font-size: 2.8rem;
	font-weight: normal;

	background-color: var(--color-black);
}
/*
### base
This is the base for all our styles. In here we define:

2. font sizes
3. box-sizing *NOTE:* box-sizing http://www.paulirish.com/2012/box-sizing-border-box-ftw/
4. site wide classes


#### font sizes

```
<p class="font-small">font-small: Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nobis, laboriosam!</p>
```

```
<p class="font-large">font-large: Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nobis, laboriosam!</p>
```

```
<p class="font-xlarge">font-xlarge: Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nobis, laboriosam!</p>
```

```
<p class="font-2xlarge">font-2xlarge: Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nobis, laboriosam!</p>
```
*/

@import "variables/variables.css";


html {
  box-sizing: border-box;
  /* Change 1 rem to mean 10px in a standard browser */
  font-size: 62.5%;

  lost-center: var(--site-max-width);
}

.f-skip-to-content {
  position: absolute;
  transform: translate(20rem, -6rem);
  transition: transform 325ms ease-in;
}
.f-skip-to-content:focus {
  transform: translate(20rem, 0);
}

@media screen and (--viewport-max-scale) {
  html {
    font-size: 1vw; /* scaling */
  }
}
@media screen and (--viewport-mobile) {
  html {
    font-size: 1.625vw; /* 16/10 */
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;

  font-size: 1.6rem;

  background-color: var(--color-black);
}

* { font-family: 'roboto', sans-serif; }

.font-small {
  font-size: small;
}
.font-large {
  font-size: large;
}
.font-xlarge {
  font-size: x-large;
}
.font-2xlarge {
  font-size: xx-large;
}

a {
  color: var(--color-red);
  text-decoration: none;
}

.f-button-icon a {
  color: var(--color-black, black);
}

.f-navigation a {
  color: var(--color-grey-light, hsla(0, 0%, 84.7%, 1));
}

.f-left-heading .f-thumbnail-big__heading a {
  color: var(--color-black, black);
}

span {
  color: var(--color-red);
}

.f-page-wrapper {
  lost-center: var(--page-max-width);
}

.f-page-wrapper_indent {
  lost-center: var(--page-max-width-indent);
}

.f-mt1 { margin-top: .5em; }
.f-mt2 { margin-top: 1em; }
.f-mt3 { margin-top: 1.5em; }
.f-mt4 { margin-top: 2em; }
.f-mt5 { margin-top: 2.5em; }
.f-mt6 { margin-top: 3em; }

@media screen and (--viewport-mobile) {
	.not-mobile {
		display: none;
	}
}
@media screen and (--viewport-desktop) {
  .not-desktop {
    display: none;
  }
}


/*
## f-one-liner

One line of text. Can contain link(s). If you want you can (ab)use this block as a multiple line text.
Just wrap it in a small grid column. `Top` and bottom margin can be achieved by inserting elements.

<em>The part about a grid column should be explained. I'm not sure what the purpose would be...</em>

<pre>
BLOCK f-one-liner
    MODIFIER f-one-liner_center
    MODIFIER f-one-liner_right
    MODIFIER f-one-liner_italic
    MODIFIER f-one-liner_strong
    MODIFIER f-one-liner_size_small
    MODIFIER f-one-liner_size_medium
    MODIFIER f-one-liner_size_big
</pre>

```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

	<!-- this should be used on the website -->


	<h2 class="f-one-liner">This is probably <a href="#">one more link</a> sentence.</h2>
	<h2 class="f-one-liner f-one-liner_italic">This is probably another link sentence.</h2>
	<h2 class="f-one-liner">This is actually a pretty long sentence, that is made just to see how they work, when there's more than one line. Pretty nifty, amarigt? Yes, amarigt. Amasorite.</h2>
	<p class="f-one-liner f-one-liner_strong f-one-liner_size_small">
	- In the Squatted Neighborhoods we are creating the Communities of the Future.
	</p>
	<h3 class="f-one-liner f-one-liner_italic" lang="fr">La structure de la boulangerie collective</h3>
	<h2 class="f-one-liner f-one-liner_right f-one-liner_italic">- Cheers!</h2>

	<!-- this should be used on the website -->

</div>

```
*/

@import "../variables/variables.css";

.f-one-liner {
	position: relative;
	margin: 0 0 1.4rem;

	color: var(--color-grey-dark);
	font-size: 1.8rem;
	font-weight: normal;
	line-height: 2.0rem;
}

.f-one-liner_center {
	text-align: center;
}

.f-one-liner_right {
	text-align: right;
}

.f-one-liner_italic {
	font-style: italic;
}

.f-one-liner_strong {
	color: var(--color-black);
	font-weight: bold;
}

.f-one-liner_size_small {
	font-size: 1.6rem;
}

.f-one-liner_size_medium {
	font-size: 2.0rem;
}

.f-one-liner_size_big {
	font-size: 2.3rem;
	line-height: 2.4rem;
}

.f-one-liner_size_medium, .f-one-liner_size_big {
	font-weight: bold;
}

@media (--viewport-mobile) {
  .f-one-liner {
    font-size: 2.8rem;
    line-height: 3.12rem;
  }
}

/*
Site navigation links.

```
<header class="f-navigation">
	<div class="f-row f-row_12">
		<div class="f-row__cell-4 f-row__cell-mobile-5">
			<h2 class="f-branding">Fire<span>fund</span></h2>
		</div>
		<nav class="f-row__cell-8 f-row__cell-mobile-7">
			<div class="f-row f-row_12">
				<div class="f-row__cell-3 f-row__cell_push_6 f-row__not-mobile">
					<a href="#" class="f-navigation__link">How it works</a>
				</div>
				<div class="f-row__not-desktop f-row__cell-mobile_push_6 f-row__cell-mobile-2">
					<a href="#" class="f-navigation__link">How</a>
				</div>
				<div class="f-row__cell-3 f-row__cell-mobile-3">
					<a href="#" class="f-navigation__link">About</a>
				</div>
			</nav>
		</div>
	</div>
</header>
```
*/

@import "../variables/variables.css";

.f-navigation {
	width: 100%;

	margin: 3rem 0 2rem 0;

	lost-center: var(--page-max-width);
}

.f-navigation__link {
	color: var(--color-grey-light, hsla(0, 0%, 84.7%, 1));
	font-size: 1.8rem;
	font-weight: lighter;
	line-height: 4.8rem;
	text-decoration: none;
	text-align: right;

	cursor: pointer;
	transition: color 260ms ease-out;
}
.f-navigation__link:hover {
	color: var(--color-white, hsla(0, 0%, 100%, 1));
}

@media screen and (--viewport-mobile) {
	.f-navigation__link {
		font-size: 2.6rem;
	}
}

/*
# thumbnail

`.thumbnail` has 3 states. Mobile, Desktop and `.thumbnail--big`. The latter
only takes effect on desktop.

<pre>
BLOCK thumbnail
MODIFIER thumbnail--big
	ELEMENT thumbnail__row (used with `thumbnail--big` to create a row with text / image)
	ELEMENT thumbnail__image-container (used with `thumbnail--big` to create a row with text / image)
	ELEMENT thumbnail__image (the image)
	ELEMENT thumbnail__info (used with `thumbnail--big` to create a row with text / image)
	ELEMENT thumbnail__content (This is all content for the specific campaign - minus the 'data' (__progress))
	ELEMENT thumbnail__heading (used with `f-left-heading`)
	ELEMENT thumbnail__byline (The 'sender')
	ELEMENT thumbnail__text
	ELEMENT thumbnail__conclusion (SUCCESS/FAILED - hidden with `thumbnail--big`)
	ELEMENT thumbnail__progress-bar (used with `.progress-bar`)
	ELEMENT thumbnail__progress-bar__value (used with `.progress-bar`)
	ELEMENT thumbnail__progress-data (used with `.progress-data`)
	ELEMENT thumbnail__progress-data-element (used with `.progress-data`)
</pre>

In some places, `lost` is used to set the width.

Documentation for Lost Grid: http://lostgrid.org/

> Lost Grid is a powerful grid system built in PostCSS that works with any preprocessor and even vanilla CSS.

```
<div class="f-row f-column_3">
	<div class="f-row__cell-2 f-row__cell-mobile">

<section class="thumbnail">
	<div class="thumbnail__row">
		<a class="thumbnail__image-container" href="#">
			<img
				class="thumbnail__image"
				alt="thumbnail image for WEST JAVA, INDONESIA"
				src="assets/image/t-antiwarprisoners2022.jpg" />
		</a>

		<div class="thumbnail__info">
			<article class="thumbnail__content">
				<a href="/schtransport">
					<h2 class="thumbnail__heading f-left-heading">Support Russian anti-war prisoners</h2>
				</a>
				<h3 class="thumbnail__byline">Out of Africa!</h3>
				<p class="thumbnail__text">
					We are launching a new round of fundraising for anti-war prisoners.
				</p>
				<p class="thumbnail__conclusion">FAILED</p>
			</article>
		</div>
	</div>

	<section class="thumbnail__progress-bar progress-bar" data-value="128">
		<div class="thumbnail__progress-bar__value progress-bar__value" style="width:0%;"></div>
	</section>

	<div class="thumbnail__progress-data">
		<section class="progress-data">
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>0%</span> of €670
			</p>
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>€0</span> pledged
			</p>
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>2022-11-12</span>
			</p>
		</section>
	</div>
</section>

	</div>
	<div class="f-row__cell-2 f-row__cell-mobile">

<section class="thumbnail">
	<div class="thumbnail__row">
		<a class="thumbnail__image-container" href="#">
			<img
				class="thumbnail__image"
				alt="thumbnail image for WEST JAVA, INDONESIA"
				src="assets/image/t-schtransport.jpg" />
		</a>

		<div class="thumbnail__info">
			<article class="thumbnail__content">
				<a href="/schtransport">
					<h2 class="thumbnail__heading f-left-heading">Let's positively interact with children for a better world</h2>
				</a>
				<h3 class="thumbnail__byline">Out of Africa!</h3>
				<p class="thumbnail__text">
				Make a positive contribution to school transportation for refugee children for a better tomorrow and better world for all
				</p>
				<p class="thumbnail__conclusion"><span>SUCCESS</span></p>
			</article>
		</div>
	</div>

	<section class="thumbnail__progress-bar progress-bar" data-value="128">
		<div class="thumbnail__progress-bar__value progress-bar__value"></div>
	</section>

	<div class="thumbnail__progress-data">
		<section class="progress-data">
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>128%</span> of €670
			</p>
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>€859</span> pledged
			</p>
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>2022-11-12</span>
			</p>
		</section>
	</div>
</section>

	</div>
	<div class="f-row__cell-2 f-row__cell-mobile">

<section class="thumbnail">
	<div class="thumbnail__row">
		<a class="thumbnail__image-container" href="#">
			<img
				class="thumbnail__image"
				alt="thumbnail image for WEST JAVA, INDONESIA"
				src="assets/image/t-schtransport.jpg" />
		</a>

		<div class="thumbnail__info">
			<article class="thumbnail__content">
				<a href="/schtransport">
					<h2 class="thumbnail__heading f-left-heading">Let's positively interact with children for a better world</h2>
				</a>
				<h3 class="thumbnail__byline">Out of Africa!</h3>
				<p class="thumbnail__text">
				Make a positive contribution to school transportation for refugee children for a better tomorrow and better world for all
				</p>
				<p class="thumbnail__conclusion"><span>SUCCESS</span></p>
			</article>
		</div>
	</div>

	<section class="thumbnail__progress-bar progress-bar" data-value="128">
		<div class="thumbnail__progress-bar__value progress-bar__value"></div>
	</section>

	<div class="thumbnail__progress-data">
		<section class="progress-data">
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>128%</span> of €670
			</p>
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>€859</span> pledged
			</p>
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>2022-11-12</span>
			</p>
		</section>
	</div>
</section>

	</div>
	<div class="f-row__cell-2 f-row__cell-mobile">

<section class="thumbnail">
	<div class="thumbnail__row">
		<a class="thumbnail__image-container" href="#">
			<img
				class="thumbnail__image"
				alt="thumbnail image for WEST JAVA, INDONESIA"
				src="assets/image/t-schtransport.jpg" />
		</a>

		<div class="thumbnail__info">
			<article class="thumbnail__content">
				<a href="/schtransport">
					<h2 class="thumbnail__heading f-left-heading">Let's positively interact with children for a better world</h2>
				</a>
				<h3 class="thumbnail__byline">Out of Africa!</h3>
				<p class="thumbnail__text">
				Make a positive contribution to school transportation for refugee children for a better tomorrow and better world for all
				</p>
				<p class="thumbnail__conclusion">FAILED</p>
			</article>
		</div>
	</div>

	<section class="thumbnail__progress-bar progress-bar" data-value="0">
		<div class="thumbnail__progress-bar__value progress-bar__value" style="width:0%;"></div>
	</section>

	<div class="thumbnail__progress-data">
		<section class="progress-data">
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>0%</span> of €1000
			</p>
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>€0</span> pledged
			</p>
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>2022-11-12</span>
			</p>
		</section>
	</div>
</section>

	</div>
</div>
```

## thumbnail--big

```
<section class="thumbnail thumbnail--big">
	<div class="thumbnail__row">
		<a class="thumbnail__image-container" href="#">
			<img
				class="thumbnail__image"
				alt="thumbnail image for WEST JAVA, INDONESIA"
				src="assets/image/t-schtransport.jpg" />
		</a>

		<div class="thumbnail__info">
			<article class="thumbnail__content">
				<a href="/schtransport">
					<h2 class="thumbnail__heading f-left-heading">HELP US BUILD AN AUTONOMOUS SPACE</h2>
				</a>
				<h3 class="thumbnail__byline">Out of Africa!</h3>
				<p class="thumbnail__text">
				Amorfati Space is trying to develop its space into an infoshop that actively distributes anti-authoritarian discourses.
				</p>
				<p class="thumbnail__conclusion"><span>SUCCESS</span></p>
			</article>
		</div>
	</div>

	<section class="thumbnail__progress-bar progress-bar" data-value="50">
		<div class="thumbnail__progress-bar__value progress-bar__value" style="width:50%;"></div>
	</section>

	<div class="thumbnail__progress-data">
		<section class="progress-data">
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>50%</span> of €670
			</p>
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>€335</span> pledged
			</p>
			<p class="thumbnail__progress-data-element progress-data__element">
				<span>Last day!</span>
			</p>
		</section>
	</div>
</section>
```
*/

@import "../variables/variables.css";

.thumbnail {
	min-height: 50.3rem;
	min-width: 28rem;
	margin-bottom: calc(3 * var(--gutter-width));

	border-bottom: 1px solid var(--color-grey-light);
	background-color: var(--color-white);

	transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), filter 0.3s ease-in-out;
	transform: scale(1);
}
.thumbnail__image {
	height: 35rem;
	min-height: 35rem; /* in case the image does not load */
	width: 100%;
	margin: 0 0 2rem 0;

	object-fit: cover;
	object-position: 50% 50%;
}
.thumbnail__content {
	position: relative; /* for .thumbnail__conclusion */

	height: 42rem;
	margin-bottom: 1rem;

	color: var(--color-grey-dark);
	font-weight: lighter;
}
.thumbnail__heading {
	margin-top: 0;
	margin-bottom: 1rem;

	border-radius: 0 0.3rem 0.3rem 0;

	font-size: 4.4rem;
	font-weight: lighter;
	line-height: 5rem;
}
.thumbnail__byline {
	display: none;
}
.thumbnail__text {
	color: var(--color-grey-dark);
	font-size: 2.4rem;
	font-weight: 300;
	line-height: 3.4rem;
}
.thumbnail__conclusion {
	position: absolute;
	bottom: 0;
	right: 0;
	margin: 0;

	text-align: right;
	font-size: 4.2rem;
}
.thumbnail__content,
.thumbnail__progress-bar,
.thumbnail__progress-data {
	margin-right: var(--gutter-width);
	margin-left: var(--gutter-width);
}
.thumbnail__progress-bar,
.thumbnail__progress-bar__value {
	height: .8rem;
	border-radius: 10px;
}
.thumbnail__progress-bar {
	margin-bottom: 2rem;
}
.thumbnail__progress-data {
	margin-bottom: 2.4rem;
}
.thumbnail__progress-data-element {
	font-size: 2.35rem;
}
.thumbnail__progress-data-element span {
	font-weight: 500;
}
@media (--viewport-desktop) {
	.thumbnail {
		margin-bottom: var(--gutter-width);
		clip-path: border-box;
		border: 1px solid var(--color-grey-light);
		border-radius: 13px;
	}

	.thumbnail:hover {
	    transform: scale(1.02);
	}

	.thumbnail__image {
		height: 22rem;
		min-height: 22rem; /* in case the image does not load */

		border-top-right-radius: 12px;
		border-top-left-radius: 12px;

		filter: grayscale(100%);
		transition: .2s all cubic-bezier(0.18, 0.89, 0.32, 1.28);
	}
	.thumbnail:hover .thumbnail__image {
		filter: grayscale(0%);
	}
	.thumbnail__content {
		height: 26rem;
	}
	.thumbnail__heading {
		font-size: 1.8rem;
		line-height: normal;
	}
	.thumbnail__text {
		color: var(--color-grey);
		font-size: 1.4rem;
		font-weight: lighter;
		line-height: 1.7rem;
	}
	.thumbnail__conclusion {
		font-size: 1.6rem;
	}
	.thumbnail__progress-bar,
	.thumbnail__progress-data {
		margin-bottom: 0.4rem;
	}
	.thumbnail__progress-data-element {
		margin: 0;

		font-size: 1rem;
	}
	.thumbnail__progress-data-element span {
		font-weight: 700;
	}
}

.thumbnail--big .thumbnail__conclusion {
	display: none;
}

.thumbnail--big .thumbnail__image {
	border-radius: 0;
}

@media (--viewport-desktop) {
	.thumbnail--big {
		/* reset min-height from .thumbnail */
		min-height: auto;
		/* center thumbnail */
		max-width: var(--page-max-width);
		margin-left: auto;
		margin-right: auto;
		/* end centering */
		padding: 2.8rem 0 1.8rem;
	}
	.thumbnail--big .thumbnail__row {
		lost-flex-container: row;
	}
	.thumbnail--big .thumbnail__image-container,
	.thumbnail--big .thumbnail__info {
		lost-column: 1/2 flex;
		lost-move: 1/2;
	}
	.thumbnail--big .thumbnail__info {
		lost-move: -1/2;
	}
	.thumbnail--big .thumbnail__image {
		margin-bottom: 1.4rem;
		padding-right: var(--gutter-width);
	}
	.thumbnail--big .thumbnail__content {
		height: auto;
		min-height: 22.8rem; /* magic number */
		margin-right: 0;

		color: var(--color-grey);
	}
	.thumbnail--big .thumbnail__heading {
		width: 100%;

		font-size: 2.5rem;
		font-weight: 400;
	}
	.thumbnail--big .thumbnail__byline {
		display: block;
		margin: 0 0 2rem;

		color: var(--color-grey);
		font-size: 1.2rem;
		font-weight: bold;
	}
	.thumbnail--big .thumbnail__text {
		color: var(--color-grey-dark);
		font-size: 1.6rem;
		font-weight: lighter;
		line-height: 2rem;
	}
	.thumbnail--big .thumbnail__progress-bar,
	.thumbnail--big .thumbnail__progress-bar__value {
		height: 1rem;
		border-radius: 0;
	}
	.thumbnail--big .thumbnail__progress-data {
		lost-flex-container: row;
		position: relative;
	}
	.thumbnail--big .thumbnail__progress-data .progress-data {
		lost-column: 1/2 flex;
		position: absolute;
		bottom: 2.8rem; /* magic number */
		padding-right: var(--gutter-width);
	}
	.thumbnail--big .thumbnail__progress-data::after {
		content: '';
		lost-column: 1/2 flex;
	}
	.thumbnail--big .thumbnail__progress-data-element {
		font-size: 1.2rem;
		font-weight: 400;
	}
	.thumbnail--big .thumbnail__progress-data-element span {
		color: var(--color-red);
	}
}

/*

We use this as share icons. Add `title=""` to link to see a hover popup with a description of the link. If you add a `title` to your
links, then visual disabled people can navigate our site with a program that reads the text for them.

## Default
```
<ul class="f-icon-list">
  <li><a class="f-icon-list__icon f-icon-medium" title="Checkout our medium!" href="https://medium.com/@firefundnet/latest"></a></li>
  <li><a class="f-icon-list__icon f-icon-facebook" title="[title text]" href="#"></a></li>
  <li><a class="f-icon-list__icon f-icon-twitter" title="Checkout our twitter!" href="https://twitter.com/firefundnet"></a></li>
  <li><a class="f-icon-list__icon f-icon-youtube" title="[title text]" href="#"></a></li>
  <li><a class="f-icon-list__icon f-icon-instagram" title="[title text]" href="#"></a></li>
</ul>
```

## Horizontal
```
<ul class="f-icon-list f-icon-list_horizontal">
  <li><a class="f-icon-list__icon f-icon-medium" title="Checkout our medium!" href="https://medium.com/@firefundnet/latest"></a></li>
  <li><a class="f-icon-list__icon f-icon-facebook" title="[title text]" href="#"></a></li>
  <li><a class="f-icon-list__icon f-icon-twitter" title="Checkout our twitter!" href="https://twitter.com/firefundnet"></a></li>
  <li><a class="f-icon-list__icon f-icon-youtube" title="[title text]" href="#"></a></li>
  <li><a class="f-icon-list__icon f-icon-instagram" title="[title text]" href="#"></a></li>
</ul>
```
*/

@import "../variables/variables.css";

.f-icon-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;

  font-size: 4rem;
  color: var(--color-white);

  list-style: none;
}

a.f-icon-list__icon {
  display: inline-block;
  margin-right: 1rem;

  color: unset;

  transition: color 250ms linear 0s;
}

a.f-icon-list__icon:hover {
  color: var(--color-grey-light);
}

.f-icon-list_horizontal {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

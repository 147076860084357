/*
Basic button.

TODO: we should change the animation to use background with linear-gradient as in `f-button-group.css`
to aleviate a color issue in Chrome.

This is pr. 2.0 only used as 'Encrypt' and 'Reset' on contact-pages, but more is to come.
Remember the BEM-structure: All buttons are it's own block. This is not a block, where the other buttons are it's elements.
This is stand-alone.

<pre>
BLOCK f-button-basic
</pre>

## f-button-basic
```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

<!-- this should be used on the website -->
	<button class="f-button-basic">encrypt</button>
	<button class="f-button-basic">reset</button>
<!-- this should be used on the website -->

</div>
```
*/

@import "../variables/variables.css";

.f-button-basic_exit {
	position: fixed;
	display:block;
	top: 1.76rem;
  	right: 3.4rem;
	width:5rem;
	height:5rem;

	border-width:1rem;
	border-style: solid;
	border-color:white;
	border-radius:100%;
	background: linear-gradient(-45deg, transparent 0%, transparent 46%, black 46%,  black 56%,transparent 56%, transparent 100%),
				linear-gradient(45deg, transparent 0%, transparent 46%, black 46%,  black 56%,transparent 56%, transparent 100%);
	background-color:white;

	box-shadow:0px 0px 5px 2px rgba(0,0,0,0.5);
	transition: all 0.3s ease;
	cursor: pointer;
}

.f-button-basic {
	position: relative;
	top: 0;
	min-width: 16.4rem;
	margin: 0 3rem 3.2rem 0;
	padding: 0; /*KLUDGE: padding set to 0 to avoid text moving while animating in Firefox */

	color: var(--color-black, black);
	font-size: 2.5rem;
	font-weight: bold;
	text-transform: capitalize;
	text-align: center;

	background-color: var(--color-white);
	border: .2rem solid var(--color-black); /*To do: can't choose between 0.2 og 0.3 ...*/
	border-radius: 0.5rem;
	box-shadow: 0 0rem 0 var(--color-black), inset 0 0rem 0 var(--color-black);

	transition-property: box-shadow, top;
	transition-duration: 100ms;
	transition-timing-function: linear;

	cursor: pointer;
	outline: 0 none;
}

.f-button-basic::-moz-focus-inner {
	border: 0; /*KLUDGE: remove outline in Firefox */
}

.f-button-basic_toggle {
	width: 70%;
	font-weight: lighter;
	font-size: 1.6rem;
	border-width: 0.1rem;
	border-color: var(--color-black);
	height: 4.2rem;
	border: .1rem solid var(--color-black); /*To do: can't choose between 0.2 og 0.3 ...*/
	border: .2rem solid var(--color-black);
	box-shadow: 0 0rem 0 var(--color-grey-light), inset 0 0rem 0 var(--color-black);
}

.f-button-basic:hover {
	box-shadow: 0 .2rem 0 var(--color-black), inset 0 -.2rem 0 var(--color-black);
	border-color: var(--color-black);
}

.f-button-basic:active {
	top: .2rem;
	/*box-shadow: 0 0rem 0 var(--color-black), inset 0 -.2rem 0 var(--color-black);*/
}

@media screen and (--viewport-mobile) {
	.f-button-basic, .f-payment__button {
		font-size: 3.8rem;
	}
}

/*
<style class="This is icon DEMO styling">
	.codedemo span {
		display: inline-block;
		margin: 2rem;

		color: white;
		font-size: 3rem;
	}
</style>

You almost always need to set `color` and `font-size`.

## All icons
```
	<span class="f-icon-medium"></span>
	<span class="f-icon-facebook"></span>
	<span class="f-icon-twitter"></span>
	<span class="f-icon-youtube"></span>
	<span class="f-icon-instagram"></span>
```
*/

@font-face {
	font-family: 'icomoon';
	src:url('https://firefund-assets.s3.amazonaws.com/fonts/icomoon.ttf?v2') format('truetype'),
			url('https://firefund-assets.s3.amazonaws.com/fonts/icomoon.woff?v2') format('woff'),
			url('https://firefund-assets.s3.amazonaws.com/fonts/icomoon.svg?v2#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="f-icon-"]::before, [class*=" f-icon-"]::before {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;

	/* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.f-icon-medium::before {
	content: "\e900";
}
.f-icon-facebook::before {
	content: "\e901";
}
.f-icon-twitter::before {
	content: "\e902";
}
.f-icon-youtube::before {
	content: "\e904";
}
.f-icon-instagram::before {
  content: "\f16d";
}

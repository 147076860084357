
/*
# f-hero2

Hero. Has a full width [of the site content] background image with text content on top.


<pre>
BLOCK f-hero2
	MODIFIER f-hero_big
	ELEMENT f-hero__wrapper
		ELEMENT f-hero__heading
		MODIFIER f-hero__heading_typewriter
		ELEMENT f-hero__text
</pre>

## With grid
```
<div class="f-hero2">
	<div class="f-hero__wrapper">
		<div class="f-row f-row_6">
			<div class="f-row__cell">
				<div class="f-hero__heading">
					<h1><span>Project:</span></h1><br>
					<h1>Rebuild Kobanê</h1>
				</div>
			</div>
			<div class="f-row__cell-2 f-row__cell-mobile-5">
				<p class="f-hero__text">
					<span>We have always dealt with oppression, but we do not only fight</span>
					<span>against ISIS - we fight for freedom. We are the Kobanê</span>
					<span>Reconstruction Board, and this is a global call to action, to stand</span>
					<span>with us and rebuild our city.</span>
				</p>
			</div>
		</div>
	</div><!-- /f-hero__wrapper -->
</div>
```
*/

@import "../variables/variables.css";

/*:root {
	--debug-height:160rem;
}
.debug, .debug::after, .debug::before {
    position: absolute;
    width: 1px;
    height: 150rem;
    background: red;
    z-index: 10;
    content: '';
}
.debug_left {
	left: 0;
}
.debug_left::before {
    left: 2.6rem;
}
.debug_left::after {
	left: var(--gutter-width);
}
.debug_right {
	right: 0;
}
.debug_right::before {
	right: 2.6rem;
}
.debug_right::after {
	right: var(--gutter-width);
}*/

.f-hero2 {
	height: 31rem;
	width: 100%;
	min-width: calc(var(--gutter-width) + .2rem);
	margin-bottom: 3rem;
	padding-top: 4rem;

	background-color: black;
	background-repeat: no-repeat;
	background-position: center bottom; /* cut sides until media query limit */
}
/*
## TODO: Needs to be done in js
If the window is below or equal the image width then `background-size: cover;`.

E.I. the sides are cut.

If thw window is above the image width, then `background-size: auto;`

E.I. the top of the image is cut.

The image image width should be measured from the actual image via js.
*/
@media screen and (min-width: 1350px) {
	.f-hero2 {
		background-size: cover;
	}
}

.f-hero2_prisonsoli {
	height: 80rem;
	background-image: url(https://firefund-assets.s3.amazonaws.com/t-prisonsoli.jpg);
}

.f-hero2__wrapper {
	lost-center: var(--page-max-width);
}

.f-hero2__heading {
	display: inline-block;

	margin: 0 0 0 2.6rem;
    padding-right: 1.4rem;
    padding-left: 0.6rem;

	background-color: var(--color-black-transparent);

	color: var(--color-white);
	font-size: 7.7rem;
	font-weight: lighter;
	white-space: nowrap;
}
[dir="rtl"] .f-hero2__heading {
	margin: 0 2.6rem 0 0;
    padding-left: 1.4rem;
    padding-right: 0.6rem;
}

.f-hero2__heading_link a {
	color: var(--color-white);
}

.f-hero2__text {
	margin: 0.16rem 0 0 var(--gutter-width);
}

[dir="rtl"] .f-hero2__text {
	margin: 0.16rem var(--gutter-width) 0 0;
}

.f-hero2__text span {
	margin-left: -0.8rem;
	padding: 0.8rem;

	background-color: var(--color-black-transparent);

	color: var(--color-white);
	font-size: 1.8rem;
	font-weight: lighter;
	line-height: 4.1rem;
	white-space: nowrap;
}

[dir="rtl"] .f-hero2__text span {
	margin-left: unset;
	margin-right: -0.8rem;
}

/*
## ribbon

If you want to add the ribbon to a container that is not the entire [viewport](https://developer.mozilla.org/en-US/docs/Glossary/Viewport),
as seen below, you need to add `overflow:hidden` and either `transform`, `perspective`, or `filter` property to that container.

The example shown below, has a container with `perspective:1rem;`.

```
<div style="perspective:1rem;height:40rem;overflow:hidden;"><!-- only here for the style guide - do not use on the website -->

<a class="f-ribbon"
   href="https://www.firefund.net/"
   title="You are on a testing version of Firefund - click here to go to the real Firefund.net"
   >Test Website!</a>

</div><!-- the <div> is only here for the style guide - do not use on the website -->
```

```
<div style="perspective:1rem;height:40rem;overflow:hidden;"><!-- only here for the style guide - do not use on the website -->

<a class="f-ribbon"
   href="https://www.firefund.net/"
   title="You are on a preview version of Firefund - click here to go to the real Firefund.net"
   >Preview Website!</a>

</div><!-- the <div> is only here for the style guide - do not use on the website -->
```
*/

@import "../variables/variables.css";

.f-ribbon {
  position: fixed;
  display: inline-block;
  top: 10rem;
  right: -15rem;
  width: 60rem;
  padding: 0.6rem 0;

  background-color: var(--color-red);
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 3px 0px;

  color: var(--color-white);
  font-size: x-large;
  text-align: center;

  transform: rotate(45deg);
  overflow: hidden;
  z-index: 99999;
}

@media screen and (--viewport-desktop) {
  .f-ribbon {
    font-size: xx-large;
  }
}

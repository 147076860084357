/*
# f-text

standard heading and text for bigger text blogs.

<pre>
BLOCK f-text
MODIFIER f-text_attention
MODIFIER f-text_justify
MODIFIER f-text--right
    ELEMENT f-text__heading
    ELEMENT f-text__text
    ELEMENT f-text__quote
    ELEMENT f-text__author
    ELEMENT f-text__note
        MODIFIER f-text__quote_source
</pre>

## EXAMPLE 1
```
<div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->


    <section class"f-text">

        <h2 class="f-text__heading">Heading explaining what the text will be about</h2>

        <p class="f-text__text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
        </p>

        <p class="f-text__text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
        </p>

    </section>

</div>
```
## Example 2 - with links and attention modifier
```
    <div class="kalei-background-white"> <!-- only here for the style guide - should not be used on the website -->

    <section class="f-text f-text_attention">
        <h2 class="f-text__heading">Heading explaining what the text will be about</h2>

        <p class="f-text__text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? <a href="#">Dolorem quasi</a> earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            Lorem ipsum dolor sit amet, <a href="#">consectetur adipisicing</a> elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur <a href="#">excepturi</a> et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
        </p>

        <p class="f-text__text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, <a href="#">tenetur excepturi</a> et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, <a href="#">tenetur excepturi</a> et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            <a href="">Lorem ipsum dolor</a> sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque voluptate fugiat.
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Nulla perspiciatis, atque id, tenetur excepturi et repellendus dolorem
            necessitatibus corporis fuga? Dolorem quasi earum omnis nam obcaecati
            autem doloremque <a href="#">voluptate fugiat.</a>
        </p>
    </section>

</div>
```
*/

@import "../variables/variables.css";

.f-text {}

.f-text__text a {
	word-wrap: break-word;
}

.f-text--right {
    text-align: right;
}

.f-text_attention .f-text__heading {
    color: var(--color-red);
}

.f-text_justify {
    text-align: justify;
}

.f-text__heading {
    margin-top: 0rem;
    margin-bottom: 0.65rem;

    color: var(--color-grey-dark);
    font-weight: normal;
    font-size: 2rem;
}

.f-text__text {
    margin-top: 0rem;
    margin-bottom: 2.7rem;

    color: var(--color-grey-dark);
    font-weight: lighter;
    font-size: 1.6rem;
    line-height: 1.82rem;
    text-rendering: optimizeLegibility;
}

.f-text__quote {
    font-style: italic;
    color: var(--color-grey-dark);
    font-size: 1.9rem;
    text-rendering: optimizeLegibility;
    opacity: 0.8;
    text-indent: 3rem;
}

.f-text__quote_source {
    color: var(--color-grey-dark);
    font-size: 1.1rem;
    font-weight: 100;
    text-rendering: optimizeLegibility;
    text-align: right;
    opacity: 0.8;
    margin: -1.6rem 5rem 2rem;
}

.f-text__author {
    color: var(--color-grey-dark);
    font-size: 1.1rem;
    font-weight: 100;
    text-rendering: optimizeLegibility;
    text-align: left;
    opacity: 0.8;
    margin: -1.6rem 0rem 2rem;
}

.f-text__note {
    font-size: 1rem;
    margin-top: -2.2rem;
    font-weight: 200;
    text-align: right;
}

.f-text__text_small {

    color: var(--color-grey-dark);
    font-size: 1.4rem;
    text-rendering: optimizeLegibility;
    opacity: 0.8
}

.f-text__text_cuff {
    font-weight: 700;
}

.f-text__text a:hover {
  text-decoration: underline;
}

.f-text__list {
    line-height: 0rem;
}

@media (--viewport-mobile) {
  .f-text__text {
    font-size: 2.6rem;
    line-height: 3.12rem;
    font-weight: 300;
  }

  .f-text__quote {
    font-size: 2.9rem;
  }

  .f-text__heading {
    font-size: 3.7rem;
  }

  .f-text__author {
    font-size: 1.1rem;
  }

  .f-text__text_cuff {
    font-size: 2.7rem;
    line-height: 3.12rem;
  }
}

#main > div.ql-editor > p {
    margin-left:  3.4rem;

    margin-top: 0rem;
    margin-bottom: 2.7rem;

    color: var(--color-grey-dark);
    font-weight: lighter;
    font-size: 1.6rem;
    line-height: 1.82rem;
    text-rendering: optimizeLegibility;
}

#about > div.ql-editor > p {
    margin-left:  3.4rem;

    margin-top: 0rem;
    margin-bottom: 2.7rem;

    color: var(--color-grey-dark);
    font-weight: lighter;
    font-size: 1.6rem;
    line-height: 1.82rem;
    text-rendering: optimizeLegibility;
}

@media screen and (--viewport-mobile) {
    #about > div.ql-editor > p {
        font-size: 2.6rem;
    }

    #main > div.ql-editor > p {
        font-size: 2.6rem;
    }
}

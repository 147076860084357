/*
# progress-bar & progress-data

Progress bar and some data. Used everywhere!

## BEM
<pre>
BLOCK progress-bar
	ELEMENT progress-bar__value
</pre>

<pre>
BLOCK progress-data
MODIFIER f-progress_vertical
	ELEMENT progress-data__element
</pre>

## Example default
```
<section class="f-content">

	<div class="progress-bar" data-value="24">
		<div class="progress-bar__value" style="width:24%;"></div>
	</div>

	<div class="progress-data">
		<p class="progress-data__element">
			<span>34%</span> of € 5000
		</p>
		<p class="progress-data__element">
			<span>€1200</span> reached in total
		</p>
		<p class="progress-data__element">
			<span>51</span> days left
		</p>
	</div>

</section>
```

## Example vertical
```
<section class="f-content">

	<div class="progress-bar" data-value="24">
		<div class="progress-bar__value" style="width:24%;"></div>
	</div>

	<div class="progress-data progress-data--vertical">
		<p class="progress-data__element">
			<span>34%</span> of € 5000
		</p>
		<p class="progress-data__element">
			<span>€1200</span> reached in total
		</p>
		<p class="progress-data__element">
			<span>51</span> days left
		</p>
	</div>

</section>
```

*/

@import "../variables/variables.css";


.progress-bar {
	height: 1rem;
	margin-bottom: 1rem;

	background-color: var(--color-grey-dark);
}
.progress-bar__value {
	height: 1rem;
	max-width: 100%;
	padding: inherit;

	background-color: var(--color-red);

	transition: width 1s ease 260ms;
	will-change: width;
}


.progress-data {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.progress-data--vertical {
	flex-direction: column;
}
.progress-data__element {
	margin: 0;
	color: var(--color-grey);
	font-size: 2.1rem;
	line-height: 3rem;
}
.progress-data__element span {
	color: var(--color-grey-dark);
	font-weight: 700;
}
@media (--viewport-desktop) {
	.progress-data__element {
		font-size: 1.6rem;
		line-height: 2.2rem;
	}
}

/*
LEGACY CODE used in:
	f-progress__milestone: occupyicela.ejs, rojava.ejs
	f-progress__reached: occupyicela.ejs
	f-progress__far: occupyicela.ejs
	f-progress__next-milestone: occupyicela.ejs
*/
.f-progress__milestone {
	font-weight: lighter;
	color: var(--color-grey-dark);
}

.f-progress__reached {
	font-weight: bold;
	color: var(--color-red) !important;
}

.f-progress__far {
	font-weight: lighter;
	opacity: .9;
}

.f-progress__next-milestone {
	margin-top: 1rem;
}

:root {
  --color-red: hsla(1, 73.7%, 38.8%, 1);
  --color-black: hsla(0, 0%, 0%, 1);
  --color-black-transparent: hsla(0, 0%, 0%, 0.65);
  --color-transparent: hsla(0, 0%, 0%, 0);
  --color-white: hsla(0, 0%, 100%, 1);
  --color-white-transparent: hsla(0, 0%, 100%, 0.9);
  --color-grey: hsla(0, 0%, 40%, 1);
  --color-grey-dark: hsla(0, 0%, 20%, 1);
  --color-grey-light: hsla(0, 0%, 84.7%, 1);
  --color-grey-light-warm: hsla(0, 15%, 97%, 1);
  --color-grey-light-extra: hsla(0, 0%, 96%, 1);

  --gutter-width: 3.4rem;
  --margin-text: 3.3rem;
  --site-max-width: 192rem;
  --page-max-width: 98.4rem;
  --page-max-width-indent: 80.4rem;
}

@custom-media --viewport-mobile (max-width: 640px);
@custom-media --viewport-desktop (min-width: 641px);
@custom-media --viewport-max-scale (max-width: 1000px);
